import { get, isEmpty, map, reduce } from "lodash";
import { BASE_URL } from "../axios";

const DB_NAME = "player-db";
const AUDIO_STORE_NAME = "audio";
const PLAYLIST_STORE_NAME = "playlist";
const IMAGE_STORE_NAME = "images";
const USER_STORE_NAME = "user";
const AUTH_STORE_NAME = "auth";

const getBlobFromSongUrl = async (songUrl) => {
  try {
    const response = await fetch(songUrl, { cache: "no-store" });

    // Check if response is valid
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Get total size for verification
    const contentLength = response.headers.get("content-length");

    // Use streams to handle large files
    const reader = response.body.getReader();
    const chunks = [];
    let receivedLength = 0;

    while (true) {
      const { done, value } = await reader.read();

      if (done) break;

      chunks.push(value);
      receivedLength += value.length;
    }

    // Verify complete download
    if (contentLength && receivedLength !== parseInt(contentLength)) {
      throw new Error("Download incomplete");
    }

    // Combine chunks into single Blob
    const blob = new Blob(chunks, {
      type: response.headers.get("content-type") || "audio/mpeg",
    });

    // Verify blob size
    if (blob.size === 0) {
      throw new Error("Downloaded file is empty");
    }

    return blob;
  } catch (error) {
    console.error("Download error:", error);
    throw error;
  }
};

const getIndexDB = async () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, 1);

    request.onupgradeneeded = (event) => {
      const database = event.target.result;
      database.createObjectStore(AUDIO_STORE_NAME, { keyPath: "id" });
      database.createObjectStore(PLAYLIST_STORE_NAME, { keyPath: "id" });
      database.createObjectStore(IMAGE_STORE_NAME, { keyPath: "id" });
      database.createObjectStore(USER_STORE_NAME, { keyPath: "id" });
      database.createObjectStore(AUTH_STORE_NAME, { keyPath: "id" });
    };

    request.onsuccess = (event) => {
      const database = event.target.result;
      resolve(database);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

export const storeAudioInDB = async (id, url) => {
  if (!url || !id) return;

  try {
    const songBlob = await getBlobFromSongUrl(url);
    const db = await getIndexDB();

    return new Promise((resolve, reject) => {
      const transaction = db.transaction([AUDIO_STORE_NAME], "readwrite");
      const objectStore = transaction.objectStore(AUDIO_STORE_NAME);

      // Add transaction complete listener
      transaction.oncomplete = () => resolve(true);
      transaction.onerror = () => reject(transaction.error);

      const request = objectStore.put({ id, songBlob, timestamp: Date.now() });

      request.onerror = () => {
        console.error("Error storing audio:", request.error);
        reject(request.error);
      };
    });
  } catch (error) {
    console.error("Failed to store audio:", error);
    throw error;
  }
};

// const getBase64DataforImage = async (image_url) => {
//   try {
//     const response = await fetch(image_url);
//     const blob = await response.blob();
//     const reader = new FileReader();
//     reader.readAsDataURL(blob); //

//     const baseData = reader.result;

//     return baseData;
//   } catch (error) {
//     console.log(error);
//   }
// };

export const storeImageInDB = async (image_url, id) => {
  if (!image_url || !id) return;

  const imageBlob = await getBlobFromSongUrl(image_url);
  const db = await getIndexDB();

  const transaction = db.transaction([IMAGE_STORE_NAME], "readwrite");
  const objectStore = transaction.objectStore(IMAGE_STORE_NAME);
  const request = objectStore.get(id);

  request.onsuccess = async (event) => {
    const existingData = event.target.result;

    if (!existingData) {
      const newData = { id, imageBlob };
      const addRequest = objectStore.add(newData);

      addRequest.onsuccess = () => { };

      addRequest.onerror = (error) => {
        console.log("Error storing data in IndexedDB:", error);
      };
    } else {
    }
  };
};

export const getDataById = async (id, store_name = AUDIO_STORE_NAME) => {
  return new Promise(async (resolve, reject) => {
    try {
      const database = await getIndexDB();
      const transaction = database.transaction([store_name], "readonly");
      const objectStore = transaction.objectStore(store_name);

      const request = objectStore.get(id);
      request.onsuccess = async (event) => {
        const data = event.target.result;
        resolve(data);
      };

      request.onerror = (error) => {
        reject(new Error("Error retrieving data from IndexedDB: " + error));
      };
    } catch (error) {
      reject(new Error("Error opening IndexedDB: " + error));
    }
  });
};

// export const storePlaylistInIndexDB = async (playlist) => {
//   // try {
//   //   const db = await getIndexDB();
//   //   const transaction = db.transaction(PLAYLIST_STORE_NAME, "readwrite");
//   //   const objectStore = transaction.objectStore(PLAYLIST_STORE_NAME);

//   //   // Store the entire playlist
//   //   const id = playlist.id;
//   //   const request = objectStore.add({
//   //     id,
//   //     playlist,
//   //   });

//   //   request.onsuccess = (e) => {
//   //     for (let i = 0; i < size(playlist.songs); i++) {
//   //       const song = playlist.songs[i];
//   //       storeAudioInDB(song.audio_track, song.id);
//   //     }
//   //   };
//   // } catch (error) {
//   //   console.error(error);
//   // }

//   //* write in promise
//   return new Promise(async (resolve, reject) => {
//     try {
//       const db = await getIndexDB();
//       const transaction = db.transaction(PLAYLIST_STORE_NAME, "readwrite");
//       const objectStore = transaction.objectStore(PLAYLIST_STORE_NAME);

//       // Store the entire playlist
//       const id = playlist.id;
//       const request = objectStore.add({
//         id,
//         playlist,
//       });

//       request.onsuccess = async (e) => {
//         // for (let i = 0; i < size(playlist.songs); i++) {
//         //   const song = playlist.songs[i];
//         //   const promises = [
//         //     storeAudioInDB(song.audio_track, song.id),
//         //     storeImageInDB(song.thumbnail_img, song.id),
//         //   ];

//         //   await Promise.allSettled(promises);
//         //   // use promise all settled
//         //   // await storeAudioInDB(song.audio_track, song.id);
//         //   // await storeImageInDB(song.thumbnail_img, song.id);
//         // }

//         const storeOperations = playlist.songs.map((song) => {
//           return Promise.allSettled([
//             storeAudioInDB(song.id, song.url),
//             storeImageInDB(song.thumbnail_img, song.id),
//           ]);
//         });

//         const results = await Promise.allSettled(storeOperations);
//         resolve(true);
//       };
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

export const storePlaylistInIndexDB = async (playlist, updateCount) => {
  //* write in promise
  return new Promise(async (resolve, reject) => {
    try {
      const db = await getIndexDB();
      const transaction = db.transaction(PLAYLIST_STORE_NAME, "readwrite");
      const objectStore = transaction.objectStore(PLAYLIST_STORE_NAME);

      // Store the entire playlist
      const id = playlist.id;
      const request = objectStore.add({
        id,
        playlist,
      });

      request.onsuccess = async (e) => {
        let downloadedSongsCount = 0;

        const promises = playlist.songs.map(async (song) => {
          const [audioResult, imageResult] = await Promise.allSettled([
            storeAudioInDB(song.id, song.url),
            storeImageInDB(song.thumbnail_img, song.id),
          ]);

          if (audioResult.status === 'fulfilled' && imageResult.status === 'fulfilled') {
            downloadedSongsCount++;
            updateCount(downloadedSongsCount); // Update the count
          }
        });

        await Promise.all(promises);
        resolve(downloadedSongsCount);
      };
    } catch (error) {
      reject(error);
    }
  });
};

export const storeUserInIndexDB = async (user, id) => {
  try {
    const db = await getIndexDB();
    const transaction = db.transaction(USER_STORE_NAME, "readwrite");
    const objectStore = transaction.objectStore(USER_STORE_NAME);

    // Store the entire user
    const request = objectStore.add({
      id,
      user,
    });

    request.onsuccess = (e) => { };
  } catch (error) {
    console.error(error);
  }
};

export const updateUserInIndexDB = async (user, id) => {
  try {
    const db = await getIndexDB();
    const transaction = db.transaction(USER_STORE_NAME, "readwrite");
    const objectStore = transaction.objectStore(USER_STORE_NAME);

    // Store the entire user
    const request = objectStore.put({
      id,
      user,
    });

    request.onsuccess = (e) => {
      console.log("User updated in IndexedDB");
    };
  } catch (error) {
    console.error(error);
  }
};

export const storeAuthInIndexDB = async (auth, id) => {
  try {
    const db = await getIndexDB();
    const transaction = db.transaction(AUTH_STORE_NAME, "readwrite");
    const objectStore = transaction.objectStore(AUTH_STORE_NAME);

    // Store the entire auth
    const request = objectStore.add({
      id,
      auth,
    });

    request.onsuccess = (e) => { };
  } catch (error) {
    console.error(error);
  }
};

export const getUserFromIndexDB = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = await getIndexDB();
      const transaction = db.transaction(USER_STORE_NAME, "readonly");
      const objectStore = transaction.objectStore(USER_STORE_NAME);
      const request = objectStore.get(id);

      request.onsuccess = (e) => {
        const data = e.target.result;
        resolve(data);
      };
    } catch (error) {
      reject(error);
    }
  });
};

export const getAuthFromIndexDB = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = await getIndexDB();
      const transaction = db.transaction(AUTH_STORE_NAME, "readonly");
      const objectStore = transaction.objectStore(AUTH_STORE_NAME);
      const request = objectStore.get(id);

      request.onsuccess = (e) => {
        const data = e.target.result;
        resolve(data);
      };
    } catch (error) {
      reject(error);
    }
  });
};

export const isExistPlaylistInIndexDb = async (playlistId) => {
  //return promise with resolve true or false
  return new Promise(async (resolve, reject) => {
    try {
      const db = await getIndexDB();
      const transaction = db.transaction(PLAYLIST_STORE_NAME, "readonly");
      const objectStore = transaction.objectStore(PLAYLIST_STORE_NAME);
      const request = objectStore.get(playlistId);

      request.onsuccess = (e) => {
        const data = e.target.result;
        if (data) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
    } catch (error) {
      reject(error);
    }
  });
};

const getAudioUrls = async () => {
  try {
    const audio_keys = await getAllKeysOfStore(AUDIO_STORE_NAME);
    const audioPromise = map(audio_keys, (i) => getDataById(i));
    const audioRes = await Promise.allSettled(audioPromise);
    const audioUrls = reduce(
      audioRes,
      (acc, i) => {
        if (i.status === "fulfilled") {
          const data = i.value;

          if (isEmpty(data)) return acc;

          const blob = data.songBlob;
          const url = URL.createObjectURL(blob);
          const id = data.id;
          return {
            ...acc,
            [id]: url,
          };
        }
        return acc;
      },
      {}
    );
    return audioUrls;
  } catch (error) {
    return {};
  }
};

const getImageUrls = async () => {
  try {
    const image_keys = await getAllKeysOfStore(IMAGE_STORE_NAME);
    const imagePromise = map(image_keys, (i) =>
      getDataById(i, IMAGE_STORE_NAME)
    );
    const imageRes = await Promise.allSettled(imagePromise);
    const imageUrls = reduce(
      imageRes,
      (acc, i) => {
        if (i.status === "fulfilled") {
          const data = i.value;

          if (isEmpty(data)) return acc;

          const blob = data.imageBlob;
          const thumbnail_img = URL.createObjectURL(blob);
          const id = data.id;

          return {
            ...acc,
            [id]: thumbnail_img,
          };
        }
        return acc;
      },
      {}
    );

    return imageUrls;
  } catch (error) {
    return {};
  }
};

export const getPlaylistsFromDB = async () => {
  const playlist_keys = await getAllKeysOfStore(PLAYLIST_STORE_NAME);

  const audioUrls = await getAudioUrls();
  const imageUrls = await getImageUrls();

  const playlistPromises = map(playlist_keys, (i) =>
    getDataById(i, PLAYLIST_STORE_NAME)
  );

  const playlistRes = await Promise.allSettled(playlistPromises);

  return map(playlistRes, (i) => {
    const playlist = get(i, "value.playlist", {});
    const songs = get(playlist, "songs", []);
    const updatedSongs = map(songs, (i) => {
      return {
        ...i,
        url: audioUrls[i.id],
        thumbnail_img: imageUrls[i.id],
      };
    });

    return {
      ...playlist,
      songs: updatedSongs,
    };
  });
};

export const getAllKeysOfStore = (storeName) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = await getIndexDB();
      const transaction = db.transaction(storeName, "readonly");
      const objectStore = transaction.objectStore(storeName);
      const keys = await objectStore.getAllKeys();

      keys.onsuccess = (e) => {
        resolve(e.target.result);
      };
    } catch (error) {
      reject(error);
    }
  });
};

// delete auth and user from indexdb onlyy

export const deleteAuthAndUserFromIndexDB = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = await getIndexDB();
      const transaction = db.transaction(
        [AUTH_STORE_NAME, USER_STORE_NAME],
        "readwrite"
      );
      const authObjectStore = transaction.objectStore(AUTH_STORE_NAME);
      const userObjectStore = transaction.objectStore(USER_STORE_NAME);

      const authRequest = authObjectStore.clear();
      const userRequest = userObjectStore.clear();

      authRequest.onsuccess = (e) => {
        resolve(e);
      };

      userRequest.onsuccess = (e) => {
        resolve(e);
      };
    } catch (error) {
      reject(error);
    }
  });
};
