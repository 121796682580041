export const ICONS = {
  MY_PLAYLIST: "MY_PLAYLIST",
  HOME: "HOME",
  LIKE_NAVBAR: "LIKE_NAVBAR",
  MY_SCHEDULE: "MY_SCHEDULE",
  PROFILE: "PROFILE",
  MAIN_LOGO: "MAIN_LOGO",
  PAUSE: "PAUSE",
  PREVIOUS: "PREVIOUS",
  SHUFFLE: "SHUFFLE",
  REPEAT: "REPEAT",
  VOLUME: "VOLUME",
  MORE_INFO: "MORE_INFO",
  DROPDOWN_ARROW: "DROPDOWN_ARROW",
  EDIT: "TEST",
  EYE_OFF: "EYE_OFF",
  EYE_ON: "EYE_ON",
  LIKE_SONG_LIST: "LIKE_SONG_LIST",
  LOGOUT: "LOGOUT",
  MUSIC: "MUSIC",
  MUTE: "MUTE",
  PLAY: "PLAY",
  RED_DELETE: "RED_DELETE",
  REFRESH: "REFRESH",
  UPLOAD: "UPLOAD",
  SEARCH: "SEARCH",
  PLUS: "PLUS",
  DELETE: "Delete",
  BACK_ARROW: "BackArrow",
  WATCH_LATER: "WatchLater",
  CHECKBOX: "Checkbox",
  ERROR: "ERROR",
  IGNORE_SONG: "IgnoreSong",
  IGNORE_ICON: "IgnoreIcon",
  ADD_TO_WATCH_LATER: "AddToWatchLater",
  DOWNLOAD: "DOWNLOAD",
};
