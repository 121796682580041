import { get, isEmpty, map, size } from "lodash";
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import cx from "classnames";

import Button from "../../components/button";
import Icons from "../../components/icons";
import { ICONS } from "../../constants/icons.constants";
import ThumbNail from "../../components/thumbNail/ThumbNail";
import Loader from "./Loader";

import styles from "./homepage.module.scss";
import musicPlayerStore from "../../zustand/musicPlayer.store";
import songsStore from "../../zustand/songs.store";
import Header from "../../components/Header";
import {
  addSongToFavorites,
  removeSongFromFavorites,
} from "../../actions/songs.actions";
import playlistStore from "../../zustand/playlist.store";
import { getSongs } from "../editPlaylistPage/editPlayListPage.helpers";
import { MEDIA_URL } from "../../axios";
import likedSongsStore from "../../zustand/likedSong.store";
import NillDisplaySchedule from "../../components/nillDisplaySchedule/NillDisplaySchedule";
import { useNavigate } from "react-router-dom";
import SongListItemWithImage from "../../components/SongListWithImage/SongListItemWithImage";
import imageStore from "../../zustand/image.store";
import { donwloadImageByName } from "../../helpers/generel.helpers";
import userStore from "../../zustand/user.store";
import { showToaster } from "../../helpers/toaster.helpers";

const Homepage = (prop, ref) => {
  const {
    currentSongIndex,
    songs: musicPlayerSongs,
    playList,
    isPlaying,
    setIsPlaying,
    setCurrentSongIndex,
    searchedSong,
  } = musicPlayerStore((state) => ({
    currentSongIndex: state.currentSongIndex,
    songs: state.songs,
    playList: state.playList,
    isPlaying: state.isPlaying,
    setIsPlaying: state.setIsPlaying,
    setCurrentSongIndex: state.setCurrentSongIndex,
    searchedSong: state.searchedSong,
  }));
  //
  const { playlists } = playlistStore((state) => ({
    playlists: state.playlists,
  }));
  //
  const {
    songs: likedSongs,
    addSong: addLikedSong,
    deleteSong: deleteLikedSong,
  } = likedSongsStore();
  const { addImage } = imageStore((state) => ({
    addImage: state.addImage,
  }));

  const { userOffline } = userStore((state) => ({
    userOffline: state.userOffline,
  }));

  //
  const [currentPlayList, setCurrentPlayList] = useState({});
  const [donwloadedImages, setDonwloadedImages] = useState({});

  const [visibleSongs, setVisibleSongs] = useState(10);

  const navigate = useNavigate();

  const buttonRef = useRef(null);

  //* Lazy loading songs
  const observer = useRef();
  const lastSongElementRef = useCallback(node => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setVisibleSongs(prevVisibleSongs => prevVisibleSongs + 5); // Load 5 more songs
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  useEffect(() => {
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) setVisibleSongs(prevVisibleSongs => prevVisibleSongs + 5); // Load 5 more songs
    });
  }, []);

  useEffect(() => {
    if (lastSongElementRef.current) {
      observer.current.observe(lastSongElementRef.current);
    }
    return () => {
      if (lastSongElementRef.current) {
        observer.current.unobserve(lastSongElementRef.current);
      }
    };
  }, [lastSongElementRef.current]);

  useEffect(() => {
    // console.log("playlists", playlists);
    if (playList?.id) setCurrentPlayList(getSongs(playList, playlists));

  }, [playList, playlists]);
  //

  // useEffect(() => {
  //   console.log("clicked0");
  //   const timeoutId = setTimeout(() => {
  //     console.log("clicked1");

  //     if (buttonRef.current) {
  //       buttonRef.current.click();
  //       console.log("clicked");
  //     }
  //   }, 4000);

  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, []);

  const { isLoading } = songsStore();

  const handleSetCurrentSong = (index) => () => {
    setCurrentSongIndex(index);
  };

  const handlePlayClick = () => {
    setIsPlaying(!isPlaying);
  };

  const handleLikeIconClick = async (song, favorite) => {
    if (userOffline) {
      return showToaster(
        "error",
        "You are offline, please check your internet connection"
      );
    }
    const { index = "1.0", id } = song;
    let isSuccessful = false;
    const isAlreadyLiked = favorite;

    if (isAlreadyLiked) {
      deleteLikedSong(id);
      // removeUnLikedSongFromPlaylist(song);
    } else {
      addLikedSong(id);
      // addLikedSongToPlaylist(song);
    }

    if (isAlreadyLiked) {
      isSuccessful = await removeSongFromFavorites({ song_id: id });
    } else {
      isSuccessful = await addSongToFavorites({ song_id: id, index });
    }

    if (!isSuccessful) {
      if (isAlreadyLiked) {
        addLikedSong(id);
      } else {
        deleteLikedSong(id);
      }
      return;
    }
  };

  const handleNewPlayListClick = () => {
    navigate("/my-playlist/new");
  };

  if (isLoading) return <Loader />;

  if (isEmpty(musicPlayerSongs))
    return (
      <div className={styles.page} style={{ display: "flex" }}>
        <NillDisplaySchedule
          head="You dont' have any songs in your playlist"
          btnPlaceholder="Create Playlist"
          iconType={ICONS.MY_PLAYLIST}
          onBtnClick={handleNewPlayListClick}
        />
      </div>
    );

  return (
    <div className={styles.page}>
      <div className={styles.first}>
        <div className={styles.topBox}>
          <div className={styles.imgbox}>
            <ThumbNail
              className={styles.thumbNail}
              name={
                playList
                  ? currentPlayList.name
                  : musicPlayerSongs[currentSongIndex]?.title
              }
              color={
                playList
                  ? currentPlayList.color
                  : musicPlayerSongs[currentSongIndex]?.color
              }
              //

              hasImage={
                playList
                  ? !!currentPlayList?.image
                  : !!musicPlayerSongs[currentSongIndex]?.image
              }
              imgSrc={`${MEDIA_URL}/playlist_images/${currentPlayList?.image}`}
              imageFileName={currentPlayList?.image}
            />
          </div>
          <div className={styles.detailsBox}>
            <div className={styles.box1}>
              <p className={styles.firstLine}>
                {searchedSong
                  ? searchedSong.title
                  : musicPlayerSongs[currentSongIndex]?.title}
              </p>
              <div className={styles.likeBox}>
                <div
                  className={cx(styles.icon, {
                    [styles.active]:
                      likedSongs?.[musicPlayerSongs[currentSongIndex]?.id],
                  })}
                  onClick={() => {
                    if (searchedSong) {
                      handleLikeIconClick(
                        searchedSong,
                        likedSongs?.[searchedSong?.id]
                      );
                      return;
                    }
                    handleLikeIconClick(
                      musicPlayerSongs[currentSongIndex],
                      likedSongs?.[musicPlayerSongs[currentSongIndex]?.id]
                    );
                  }}
                >
                  <Icons type={ICONS.LIKE_SONG_LIST} />
                </div>

                <div className={styles.btn}>
                  <Button
                    id="topPlayButton"
                    ref={buttonRef}
                    onClick={handlePlayClick}
                  >
                    {isPlaying ? "Pause" : "Play"}
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.secondLine}>
              {!playList ? (
                <p className={styles.artistName}>
                  {musicPlayerSongs[currentSongIndex]?.artist}
                </p>
              ) : (
                <div className={cx(styles.playlistName)}>
                  {currentPlayList.name || ""}
                  {/* Tooltip */}
                  <div className={cx(styles.customTooltip)}>
                    {currentPlayList.name || ""}
                  </div>
                </div>
              )}
            </div>
            <div className={styles.thirdLine}>
              <canvas
                style={{
                  width: "100%",
                  height: "100%",
                }}
                ref={ref}
              ></canvas>
            </div>
          </div>
        </div>
      </div>
      <Header columns={["Song", "Artist", "Liked"]} fromScreen="homePage" />
      <div className={styles.second}>
        {map(musicPlayerSongs.slice(0, visibleSongs), (song, index) => {
          const { id } = song;
          const isLastElement = index === visibleSongs - 1;
          return (
            <div
              key={id + "homepage" + index}
              ref={isLastElement ? lastSongElementRef : null}
            >
              <SongListItemWithImage
                continarClassName={
                  index % 2 === 0 ? styles.type1Bg : styles.type2Bg
                }
                onClick={handleSetCurrentSong(index)}
                song={song}
                onIconClick={handleLikeIconClick}
                isActive={likedSongs?.[id]}
                fromScreen="homePage"
                playListId={playList?.id}
                hasImage={donwloadedImages?.[index]}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(forwardRef(Homepage));
