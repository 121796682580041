import React from "react";
import { noop } from "lodash";

import Button from "../button/Button";
import propTypes from "prop-types";
import Icons from "../icons";

import styles from "./nillDisplaySchedule.module.scss";
import userStore from "../../zustand/user.store";

const NillDisplaySchedule = (props) => {
  const { head, iconType, btnPlaceholder, onBtnClick, hideButton } = props;
  const { userOffline } = userStore((state) => ({
    userOffline: state.userOffline,
  }));

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.iconBox}>
          <Icons type={iconType} />
        </div>
        <div className={styles.head}>{head}</div>
        {!hideButton && (
          <Button
            onClick={onBtnClick}
            className={styles.btn}
            disabled={userOffline}
          >
            {btnPlaceholder}
          </Button>
        )}
      </div>
    </div>
  );
};

NillDisplaySchedule.propTypes = {
  head: propTypes.string,
  iconType: propTypes.string,
  btnPlaceholder: propTypes.string,
  onBtnClick: propTypes.func,
};

NillDisplaySchedule.defaultProps = {
  head: "",
  iconType: "",
  btnPlaceholder: "",
  onBtnClick: noop,
};
export default NillDisplaySchedule;
