import { map } from "lodash";

import { getRandomColor } from "../helpers/generel.helpers";
import { Playlist } from "../types/playlist.types";
import { Song } from "../types/song.types";
import { MEDIA_URL } from "../axios";

export const getFormattedSong = (song: Song, isOfflinePlaylistSong = false) => {
  return {
    ...song,
    url: !!isOfflinePlaylistSong
      ? song.url
      : `${MEDIA_URL}/songs/${song.fileName}`,
    color: getRandomColor(),
  };
};

export const getFormattedSongs = (
  songs: Song[],
  areOfflinePlaylistSongs = false
) => map(songs, (item) => getFormattedSong(item, areOfflinePlaylistSongs));

export const getFormattedPlaylist = (playlist: Playlist) => {
  return {
    ...playlist,
    color: getRandomColor(),
    songs: getFormattedSongs(playlist.songs, playlist?.isOffline),
  };
};

export const getFormattedPlaylists = (playlists: Playlist[]) => {
  return playlists.map((playlist) => {
    return getFormattedPlaylist(playlist);
  });
};
