import { find, findIndex, isEmpty, map } from "lodash";
import React, { useEffect, useRef } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { fetchLikedSongs, fetchSongs } from "./actions/songs.actions";
import { doTokenRefresh, fetchCustomerData } from "./actions/user.actions";

import AppSkeleton from "./components/appSkeleton/AppSkeleton";

import { ROUTES } from "./constants/routes.constants";
import { getRefreshToken } from "./helpers/generel.helpers";
import EditPlaylistPage from "./pages/editPlaylistPage/EditPlaylistPage";
import Homepage from "./pages/homepage/Homepage";
import LikedPage from "./pages/likedPage/LikedPage";
import Login from "./pages/login";
import MyPlayListPage from "./pages/myPlaylistPage/MyPlayListPage";
import ProfilePageContent from "./pages/profilePageContent/ProfilePageContent";
import Schedule from "./pages/schedule/Schedule";
import ScheduleEditPage from "./pages/schedule/scheduleEditPage/ScheduleEditPage";

import playlistStore from "./zustand/playlist.store";
import songsStore from "./zustand/songs.store";
import userStore from "./zustand/user.store";
import { getFormattedSongs } from "./zustand/general.helper";
import musicPlayerStore from "./zustand/musicPlayer.store";
import likedSongsStore from "./zustand/likedSong.store";
import { getIngoreSongIds, shuffleArray } from "./helpers/music.helpers";
import ignoredSongsStore from "./zustand/ignoreSong.store";
import AlertPopup from "./components/alertPopup/AlertPopup";
import {
  getAuthFromIndexDB,
  getPlaylistsFromDB,
  getUserFromIndexDB,
  storeAuthInIndexDB,
  storeUserInIndexDB,
} from "./helpers/indexDb.helpers";
// import { io } from "socket.io-client";

const getPlaylistSongToShow = (playlists) => {
  const favoritePlaylist = find(playlists, (playlist) => playlist.is_favorite);

  if (isEmpty(favoritePlaylist?.songs)) {
    const playListWithSongs = find(
      playlists,
      (playlist) => playlist.songs.length > 0
    );

    const songs = playListWithSongs?.songs;

    return {
      songs: playListWithSongs?.songs,
      playlist: playListWithSongs,
      index: findIndex(songs, (song) => !song.ignore) || 0,
    };
  }

  return {
    songs: favoritePlaylist?.songs,
    playlist: favoritePlaylist,
    index: findIndex(favoritePlaylist?.songs, (song) => !song.ignore) || 0,
  };
};

const App = () => {
  const {
    isAuthenticated,
    setIsAuthenticated,
    setUser,
    userOffline,
    setUserOffline,
    user,
  } = userStore((state) => ({
    isAuthenticated: state.isAuthenticated,
    setIsAuthenticated: state.setIsAuthenticated,
    setUser: state.setUser,
    userOffline: state.userOffline,
    setUserOffline: state.setUserOffline,
    user: state.user,
  }));
  const { setIsLoading: setIsPlaylistFetching, setPlaylists } = playlistStore(
    (state) => ({
      setIsLoading: state.setIsLoading,
      setPlaylists: state.setPlaylists,
    })
  );
  const { setSongs, setIsLoading } = songsStore((state) => ({
    setSongs: state.setSongs,
    setIsLoading: state.setIsLoading,
  }));
  // const toCallOneTime = useRef(false);

  const {
    setSongs: setMusicPlayerSongs,
    songs,
    setPlayList,
    setBeforeShuffleSongs,
    toggleIsShuffle,
  } = musicPlayerStore((state) => ({
    setSongs: state.setSongs,
    songs: state.songs,
    setPlayList: state.setPlayList,
    setBeforeShuffleSongs: state.setBeforeShuffleSongs,
    toggleIsShuffle: state.toggleIsShuffle,
  }));

  const { setSongs: setLikedSongs } = likedSongsStore((state) => ({
    setSongs: state.setSongs,
  }));
  const { setSongs: setIgnoredSongs } = ignoredSongsStore((state) => ({
    setSongs: state.setSongs,
  }));

  // current route
  const currentRoute = useLocation();

  //
  const canvasRef = useRef(null);
  //
  const { isPlaying, currentAudioRefIndex, audioRef2, aoudioRef1 } =
    musicPlayerStore((state) => ({
      isPlaying: state.isPlaying,
      currentAudioRefIndex: state.currentAudioRefIndex,
      audioRef2: state.audioRef2,
      aoudioRef1: state.aoudioRef1,
    }));

  const refreshToken = getRefreshToken();

  const instanceKey = "app_instance_active";
  const instanceId = Date.now().toString();
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const checkForMultipleInstances = () => {
    const activeInstance = localStorage.getItem(instanceKey);
    if (activeInstance && activeInstance !== instanceId) {
      setIsModalVisible(true);
      return true;
    }
    return false;
  };

  // ? OFFLINE check for first time
  useEffect(() => {
    (async () => {
      console.log("userOffline check");
      if (navigator.onLine) {
        setUserOffline(false);
        console.log("loaddd  online");
      } else {
        setUserOffline(true);
        const userfromDb = await getUserFromIndexDB("user-data");
        const authData = await getAuthFromIndexDB("authentication-data");
        setUser(userfromDb.user);
        setIsAuthenticated(authData.auth);
      }
    })();
  }, []);

  useEffect(() => {
    // Set the current instance as active
    localStorage.setItem(instanceKey, instanceId);

    // Function to handle storage changes
    const handleStorageChange = (event) => {
      if (event.key === instanceKey) {
        checkForMultipleInstances();
      }
    };

    // Add event listener to listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange);

    // Check for multiple instances on load
    if (checkForMultipleInstances()) {
      return; // If another instance was found, stop further execution
    }

    // Clear the instance flag on window unload
    const handleBeforeUnload = () => {
      if (localStorage.getItem(instanceKey) === instanceId) {
        localStorage.removeItem(instanceKey);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup function
    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      if (localStorage.getItem(instanceKey) === instanceId) {
        localStorage.removeItem(instanceKey);
      }
    };
  }, []);

  // ? OFFLINE check
  useEffect(() => {
    const onLine = async () => {
      setUserOffline(false);
      console.log("online user");
    };

    const onOffline = async () => {
      setUserOffline(true);
      console.log("offline userrr");
      // toCallOneTime.current = false;

      const userfromDb = await getUserFromIndexDB("user-data");
      const authData = await getAuthFromIndexDB("authentication-data");
      setUser(userfromDb.user);
      setIsAuthenticated(authData.auth);
    };

    window.addEventListener("online", onLine);

    window.addEventListener("offline", onOffline);

    // remove ebvent listener
    return () => {
      window.removeEventListener("online", onLine);
      window.removeEventListener("offline", onOffline);
    };
  }, [user]);

  // useEffect(() => {
  //   //  import { io } from "socket.io-client";
  //   const socket = io("http://localhost:8888", {
  //     query:
  //       "token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1OGQxYmQ3NTQ5MDYxZWNiNjZhZDBlNCIsInJvbGUiOiJBUlRJU1QiLCJpYXQiOjE3MDQxMTc3MTcsImV4cCI6MTcwNDEyNDkxN30.MY_FioWAxoJVMNBKlCn0NLiMFvdWSdQfr134fPRJeqc",
  //   });
  //   socket.on("connection", () => {
  //     console.log("connected");
  //   });
  //   socket.on("connect_error", (err) => {
  //     console.log("connect_error", err.message); // prints the message associated with the error
  //   });
  // }, []);

  useEffect(() => {
    (async () => {
      console.log("useEffect before auth");
      setIsPlaylistFetching(true);
      setIsLoading(true);
      const isSuccess = await doTokenRefresh();

      if (isSuccess) {
        await storeAuthInIndexDB(true, "authentication-data");
        setIsAuthenticated(true);
      } else {
        await storeAuthInIndexDB(false, "authentication-data");
        setIsLoading(false);
        setIsPlaylistFetching(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      console.log("useEffect after auth", userOffline, isAuthenticated);
      if (userOffline) {
        setIsLoading(true);
        const userfromDb = await getUserFromIndexDB("user-data");
        const authData = await getAuthFromIndexDB("authentication-data");
        const offlinePlaylist = await getPlaylistsFromDB();

        if (authData) {
          const favouritePlayList = find(
            offlinePlaylist,
            (i) => i.is_favourite
          );
          const initialPlaylit = favouritePlayList
            ? favouritePlayList
            : offlinePlaylist?.[0];

          console.log("offline playlists", offlinePlaylist);

          const offlinePlaylistEdited = map(offlinePlaylist, (playlist) => ({
            ...playlist,
            isOffline: true,
          }));

          setUser(userfromDb.user);
          setPlaylists(offlinePlaylistEdited);
          setPlayList(initialPlaylit, 0);
          if (initialPlaylit?.songs) {
            setSongs(initialPlaylit.songs);
            setMusicPlayerSongs(initialPlaylit.songs, 0);
          }
        }
        setIsLoading(false);
      } else {
        // if (toCallOneTime.current) return;
        // toCallOneTime.current = true;

        if (!isAuthenticated) return;
        setIsLoading(true);
        //

        const [customerData, songsData, likedSongs] = await Promise.allSettled([
          fetchCustomerData(),
          fetchSongs(),
          fetchLikedSongs(),
        ]);
        setLikedSongs(likedSongs.value);

        const { customer, playlists, status } = customerData.value;

        if (!isEmpty(customer)) {
          setUser(customer);
          await storeUserInIndexDB(customer, "user-data");
        }
        if (!isEmpty(playlists)) setPlaylists(playlists);
        if (!isEmpty(songsData.value)) {
          const songs = getFormattedSongs(songsData.value);
          const {
            playlist: favoritePlaylist,
            songs: favoriteSongs,
            index,
          } = getPlaylistSongToShow(playlists);
          const musicPlayerSongs = getFormattedSongs(favoriteSongs || []);

          const initialShuffleState = status?.last_shuffle || false;
          const musicPlayerSongsToSet = initialShuffleState
            ? shuffleArray([...(musicPlayerSongs || [])], 0)
            : musicPlayerSongs;

          if (initialShuffleState) {
            setBeforeShuffleSongs([...musicPlayerSongs]);
          }

          toggleIsShuffle(initialShuffleState);
          setSongs(songs);
          setMusicPlayerSongs(musicPlayerSongsToSet, index);
          setPlayList(favoritePlaylist);
          // get song.id if song.ignore is true
          const ingoreSongIds = getIngoreSongIds(playlists);
          setIgnoredSongs(ingoreSongIds);
        }

        setIsLoading(false);
        setIsPlaylistFetching(false);
        // Socket Setup
      }
    })();
  }, [userOffline, isAuthenticated]);
  //

  // Aoudio Visualizer
  const audioContext1 = useRef(null);
  const audioContext2 = useRef(null);
  const source1 = useRef(null);
  const source2 = useRef(null);
  const analyzer1 = useRef(null);
  const analyzer2 = useRef(null);
  const getCurrentAudioRef = () =>
    currentAudioRefIndex?.current === 1 ? aoudioRef1 : audioRef2;
  const getCurrentSourceRef = () =>
    currentAudioRefIndex?.current === 1 ? source1 : source2;
  const getCurrentAnalyzerRef = () =>
    currentAudioRefIndex?.current === 1 ? analyzer1 : analyzer2;
  //
  //! Don't ever touch this useEffect
  useEffect(() => {
    const visualizeData = () => {
      const currentSongRef = getCurrentAudioRef();
      const analyzer = getCurrentAnalyzerRef();

      if (
        !currentSongRef ||
        currentSongRef.paused ||
        !analyzer.current ||
        !canvasRef.current
      ) {
        cancelAnimationFrame(visualizeData);
      }
      try {
        const songData = new Uint8Array(100);
        analyzer.current.getByteFrequencyData(songData);
        const bar_width = 4;
        let start = 0;
        const ctx = canvasRef.current.getContext("2d");
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        for (let i = 0; i < songData.length; i++) {
          // compute x coordinate where we would draw
          start = i * 7;
          //create a gradient for the  whole canvas
          let gradient = ctx.createLinearGradient(
            0,
            0,
            canvasRef.current.width,
            canvasRef.current.height
          );
          // gradient.addColorStop(0.2, "#9438fb");
          // gradient.addColorStop(0.5, "#21e4ef");
          // gradient.addColorStop(0.75, "#75df51");
          // gradient.addColorStop(1.0, "#292cf9");

          gradient.addColorStop(0.2, "#9438fb");
          gradient.addColorStop(0.5, "#9438fb");
          gradient.addColorStop(0.75, "#9438fb");
          gradient.addColorStop(1.0, "#9438fb");
          ctx.fillStyle = gradient;
          ctx.fillRect(
            start,
            canvasRef.current.height * 1.6,
            bar_width,
            -songData[i]
          );
        }

        requestAnimationFrame(visualizeData);
      } catch (err) {
        return;
      }
    };

    if (!isAuthenticated) return;
    // if(!isPlaying) return;

    if (isPlaying) {
      if (!audioContext1.current) audioContext1.current = new AudioContext();
      if (!audioContext2.current) audioContext2.current = new AudioContext();
    }

    try {
      source1.current =
        audioContext1.current.createMediaElementSource(aoudioRef1);
      analyzer1.current = audioContext1.current.createAnalyser();
      source1.current.connect(analyzer1.current);
      analyzer1.current.connect(audioContext1.current.destination);
    } catch (e) { }
    try {
      source2.current =
        audioContext2.current.createMediaElementSource(audioRef2);
      analyzer2.current = audioContext2.current.createAnalyser();
      source2.current.connect(analyzer2.current);
      analyzer2.current.connect(audioContext2.current.destination);
    } catch (e) { }
    //
    if (isPlaying) {
      audioContext1.current?.resume();
      audioContext2.current?.resume();
    } else {
      audioContext1.current?.suspend();
      audioContext2.current?.suspend();
    }

    //

    visualizeData();

    return () => {
      cancelAnimationFrame(visualizeData);
    };
  }, [isAuthenticated, isPlaying, songs, currentRoute.pathname]);
  //

  if (isModalVisible) {
    return <AlertPopup isOpen={true} />;
  }

  if (!isAuthenticated && isEmpty(refreshToken)) {
    return <Login />;
  }

  return (
    <AppSkeleton>
      <Routes>
        <Route path={ROUTES.HOME} element={<Homepage ref={canvasRef} />} />
        <Route path={ROUTES.PROFILE} element={<ProfilePageContent />} />
        <Route path={ROUTES.MY_SCHEDULE} element={<Schedule />} />
        <Route path={ROUTES.MY_SCHEDULE_EDIT} element={<ScheduleEditPage />} />
        <Route
          path={ROUTES.MY_PLAYLIST_NEW}
          element={<ScheduleEditPage isNewSchedule />}
        />
        <Route path={ROUTES.MY_PLAYLIST} element={<MyPlayListPage />} />
        <Route path={ROUTES.MY_PLAYLIST_EDIT} element={<EditPlaylistPage />} />
        <Route path={ROUTES.NEW_PLAYLIST} element={<EditPlaylistPage />} />
        <Route path={ROUTES.LIKED} element={<LikedPage />} />
      </Routes>
    </AppSkeleton>
  );
};

export default App;

// import React, { useEffect, useRef, useState } from "react";

// const SongDuration = () => {
//   const ref = useRef(null);
//   const [url, setUrl] = useState("");

//   useEffect(() => {
//     ref.current.addEventListener("loadedmetadata", () => {
//       console.log(ref.current.duration);
//     });
//   }, []);

//   // fetch audio duration
//   useEffect(() => {
//     (async () => {
//       const response = await fetch(
//         "https://dev.4-play.io/songs/01HP4HJKQZV6XSAB5WTXZKY2J7.mp3"
//       );
//       const blob = await response.blob();
//       const objectURL = URL.createObjectURL(blob);
//       setUrl(objectURL);
//     })();
//   }, []);

//   return (
//     <div>
//       <audio src={url} controls ref={ref}></audio>
//       {/* <audio
//         crossOrigin="anonymous"
//         controls
//         ref={ref}
//         // preload the metadata
//         preload="metadata"
//       >
//         <source src={url} type="audio/mp3" />
//       </audio> */}
//     </div>
//   );
// };

// export default SongDuration;
