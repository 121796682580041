import { Schedule } from "./schedule.types";
import { Song } from "./song.types";

export interface Playlist {
  id?: string;
  name?: string;
  schedule?: Schedule[];
  is_favorite?: boolean;
  owner?: boolean;
  songs?: Song[];
  color?: string;
  visibelePhoto?: string;
  isOffline?: boolean;
}

export const EMPTY_PLAYLIST: Playlist = {
  id: "",
  name: "",
  schedule: [],
  is_favorite: false,
  owner: false,
  songs: [],
  color: "",
  visibelePhoto: "",
};
