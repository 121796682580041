import { filter, get, isEmpty, map, size } from "lodash";
import { useCallback, useEffect, useState } from "react";

import Loader from "./loader";
import Header from "../../components/Header";

import { ICONS } from "../../constants/icons.constants";
import {
  fetchLikedSongs,
  removeSongFromFavorites,
} from "../../actions/songs.actions";

import styles from "./likedPage.module.scss";
import { showToaster } from "../../helpers/toaster.helpers";
import userStore from "../../zustand/user.store";
import likedSongsStore from "../../zustand/likedSong.store";
import NillDisplaySchedule from "../../components/nillDisplaySchedule/NillDisplaySchedule";
import SongListItemWithImage from "../../components/SongListWithImage/SongListItemWithImage";
import { donwloadImageByName } from "../../helpers/generel.helpers";
import imageStore from "../../zustand/image.store";

const LikedPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [songs, setSongs] = useState([]);
  // const { isAuthenticated } = userStore();
  const { deleteSong, addSong } = likedSongsStore((state) => ({
    deleteSong: state.deleteSong,
    addSong: state.addSong,
  }));
  const { addImage } = imageStore((state) => ({
    addImage: state.addImage,
  }));

  const { userOffline, isAuthenticated } = userStore((state) => ({
    userOffline: state.userOffline,
    isAuthenticated: state.isAuthenticated,
  }));

  useEffect(() => {
    (async () => {
      if (!isAuthenticated || userOffline) return;
      setIsLoading(true);
      const songs = await fetchLikedSongs();
      setSongs(songs);
      setIsLoading(false);
    })();
  }, [isAuthenticated, userOffline]);

  const handleIconClick = (id) => async () => {
    if (userOffline) {
      return showToaster(
        "error",
        "You are offline, please check your internet connection"
      );
    }
    const oldSogs = [...songs];
    const updatedSongs = filter(songs, (song) => song.id !== id);
    setSongs(updatedSongs);
    const isSucess = await removeSongFromFavorites({
      song_id: id,
    });
    deleteSong(id);
    if (!isSucess) {
      addSong(id);
      setSongs(oldSogs);
      showToaster("error", "Something went wrong");
    }
  };

  const donwloadImage = useCallback(async () => {
    for (let index = 0; index < size(songs); index++) {
      if (index >= size(songs)) return;
      const imageToDownload = get(songs, `${index}.imageFileName`, "");

      try {
        const result = await donwloadImageByName(imageToDownload);
        if (result) {
          addImage(imageToDownload);
        }
      } catch (error) {
        //
      }
    }
  }, [songs, addImage]);

  useEffect(() => {
    donwloadImage();
  }, [donwloadImage]);

  return (
    <div className={styles.page}>
      <div className={styles.header}>Liked Songs</div>
      <Header columns={["Song", "Artist", "Like"]} />
      <div className={styles.second}>
        {isLoading ? (
          <Loader />
        ) : isEmpty(songs) ? (
          <NillDisplaySchedule
            head="You don't have any liked songs"
            btnPlaceholder="Create Playlist"
            iconType={ICONS.MY_PLAYLIST}
            hideButton
          />
        ) : (
          map(songs, (song, index) => {
            const { id } = song;
            return (
              <SongListItemWithImage
                continarClassName={
                  index % 2 === 0 ? styles.type1Bg : styles.type2Bg
                }
                song={song}
                key={id + "hoem page songs"}
                onIconClick={handleIconClick(id)}
                isActive
                hasImage
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default LikedPage;
