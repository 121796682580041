import React, { useState } from "react";

import cx from "classnames";
import Button from "../../components/button";
import Input from "../../components/input";
import styles from "./profilePageContent.module.scss";
import PopUp from "../../components/popUp";
import { ICONS } from "../../constants/icons.constants";
import userStore from "../../zustand/user.store";
import { deleteAuthAndUserFromIndexDB } from "../../helpers/indexDb.helpers";

const ProfilePageContent = () => {
  const [visible, setVisible] = useState(false);

  const { user, flushUserData } = userStore((state) => ({
    user: state.user,
    flushUserData: state.flushUserData,
  }));

  const handleLogout = () => {
    setVisible((prevState) => !prevState);
  };

  const handleDeleteProfile = () => {
    localStorage.clear();
    flushUserData();
    deleteAuthAndUserFromIndexDB();
  };

  return (
    <div className={styles.pageContent}>
      <div className={styles.header}>Profile Details</div>

      <div className={styles.form}>
        <div className={styles.box}>
          <p>Username</p>
          <div className={styles.input}>
            <Input value={user.username} disabled />
          </div>
        </div>
        <div className={styles.box}>
          <p>Full Name</p>
          <div className={styles.input}>
            <Input
              value={`${user?.firstname || ""} ${user?.lastname || ""}`}
              disabled
            />
          </div>
        </div>
        <div className={styles.box}>
          <p>Email</p>
          <div className={styles.input}>
            <Input value={user?.email || ""} disabled />
          </div>
        </div>
        <div className={cx(styles.box, styles.lastBox)}>
          <p>Need Help ?</p>
          <div className={cx(styles.input, styles.contactBtn)}>
            <Button>Contact Us</Button>
          </div>
        </div>

        <div className={styles.logoutBtn}>
          <Button onClick={handleLogout}>Logout</Button>
        </div>
      </div>

      <PopUp
        iconType={ICONS.LOGOUT}
        title="Are you sure you want to logout ?"
        btnPlaceholder="Logout"
        isOpen={visible}
        setIsOpen={setVisible}
        onSuccess={handleDeleteProfile}
      />
    </div>
  );
};

export default ProfilePageContent;
