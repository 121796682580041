import React, { useState } from "react";
import { isEmpty } from "lodash";
import cx from "classnames";

import Input from "../../components/input/Input";
import logo from "../../logo.png";
import Button, { BUTTON_TYPES } from "../../components/button/Button";

import { ICONS } from "../../constants/icons.constants";
import { ERROR_FIELDS } from "./login.constants";

import { validateEmptyField } from "../../helpers/generel.helpers";
import { checkIfCanMove } from "./login.helpers";
import { doLogin } from "../../actions/user.actions";
import userStore from "../../zustand/user.store";

import styles from "./login.module.scss";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiEror] = useState("");

  const { setIsAuthenticated } = userStore((state) => ({
    setIsAuthenticated: state.setIsAuthenticated,
  }));

  const handleLoginClick = async () => {
    const { errors, canMove } = checkIfCanMove(username, password);
    if (!canMove) {
      setError(errors);
      return;
    }
    setIsLoading(true);
    const isSuccess = await doLogin(username, password);
    if (!isSuccess) {
      setApiEror("Invalid username or password");
      setIsLoading(false);
      return;
    }
    setIsAuthenticated(true);
  };

  return (
    <div className={styles.page}>
      <form type="submit">
        <div className={styles.form}>
          <div className={styles.img}>
            <img src={logo} alt="logo" className={styles.logo} />
            <div className={styles.header}>To Continue login to For Play</div>
          </div>

          <div className={styles.inputBox}>
            <Input
              placeholder="Username"
              value={username}
              setValue={setUsername}
              error={error}
              setError={setError}
              id={ERROR_FIELDS.USER_NAME}
              validator={validateEmptyField}
              type="text"
            />
            <div className={styles.input}>
              <Input
                placeholder="Password"
                icon1={ICONS.EYE_ON}
                icon2={ICONS.EYE_OFF}
                value={password}
                setValue={setPassword}
                error={error}
                setError={setError}
                id={ERROR_FIELDS.PASSWORD}
                validator={validateEmptyField}
                type="password"
                hasToggleIcon
              />
            </div>
            {!isEmpty(apiError) && (
              <p className={cx(styles.errorText, styles.shakeText)}>
                {apiError}
              </p>
            )}

            <div className={cx(styles.header, styles.forget)}>
              <a
                href="https://4-play.io/forgotpass/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Forgot ?
              </a>
            </div>
          </div>
          <div className={styles.btn}>
            <Button
              id="login_button"
              onClick={handleLoginClick}
              type={BUTTON_TYPES.PRIMARY}
              isLoading={isLoading}
            >
              Login
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
