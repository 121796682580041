import React, { memo, useRef } from "react";
import cx from "classnames";
import propTypes from "prop-types";
import { get, isEmpty, noop } from "lodash";

import Icons from "../icons";
import ThumbNail from "../thumbNail/ThumbNail";
import { ICONS } from "../../constants/icons.constants";

import styles from "./songList.module.scss";
import { ignoreSong } from "../../actions/playlist.actions";
import playlistStore from "../../zustand/playlist.store";
import { MEDIA_URL } from "../../axios";
import ignoredSongsStore from "../../zustand/ignoreSong.store";
import userStore from "../../zustand/user.store";
import { showToaster } from "../../helpers/toaster.helpers";

const SongListItemWithImage = (props) => {
  const {
    continarClassName,
    onClick,
    song,
    onIconClick,
    isActive,
    fromScreen,
    playListId,
    hasImage,
  } = props;

  const { title: songName, id, artist, color, imageFileName } = song;
  const { addLikedSongToPlaylist, removeUnLikedSongFromPlaylist } =
    playlistStore((state) => ({
      addLikedSongToPlaylist: state.addLikedSongToPlaylist,
      removeUnLikedSongFromPlaylist: state.removeUnLikedSongFromPlaylist,
    }));

  const {
    songs: ignoredSongs,
    addSong: addIgnoreSong,
    deleteSong: removeIgnoreSong,
  } = ignoredSongsStore((state) => ({
    songs: state.songs,
    addSong: state.addSong,
    deleteSong: state.deleteSong,
  }));

  const { userOffline } = userStore((state) => ({
    userOffline: state.userOffline,
  }));

  // const [isIgnore, setIsIgnore] = useState(null);

  const currentPlaylistId = playListId;
  const timeOutRef = useRef(null);

  const handleIconClick = (e) => {
    e.stopPropagation();
    onIconClick(song, isActive);
    if (isActive) {
      removeUnLikedSongFromPlaylist(song);
      return;
    }
    addLikedSongToPlaylist(song);
  };

  const handleIgnore = async (e) => {
    e.stopPropagation();
    if (userOffline) {
      return showToaster(
        "error",
        "You are offline, please check your internet connection"
      );
    }
    if (timeOutRef.current) {
      clearTimeout(timeOutRef.current);
    }

    const idForIngoreSong = id + currentPlaylistId;
    const newIgnore = get(ignoredSongs, idForIngoreSong, false) ? false : true;

    if (newIgnore) {
      addIgnoreSong(idForIngoreSong);
    } else {
      removeIgnoreSong(idForIngoreSong);
    }

    // // setIsIgnore(newIgnore);
    const isSuccess = await ignoreSong(currentPlaylistId, id, {
      ignore: newIgnore,
    });

    if (!isSuccess) {
      if (newIgnore) {
        removeIgnoreSong(idForIngoreSong);
      } else {
        addIgnoreSong(idForIngoreSong);
      }
    }
  };

  return (
    <div
      key={id}
      className={cx(styles.container, continarClassName)}
      onClick={onClick}
    >
      <div className={styles.thumbNailContainer}>
        <ThumbNail
          name={songName}
          color={color}
          className={cx(styles.thumbNail)}
          imgSrc={`${MEDIA_URL}/songs_images/${imageFileName}`}
          hasImage={hasImage && !isEmpty(imageFileName)}
          imageFileName={imageFileName}
        />
      </div>
      <div className={styles.extraInfo}>
        <p className={cx(styles.column, styles.text1)}>{songName}</p>
        <p className={cx(styles.column, styles.text2)}>{artist}</p>
        {/* <div
          className={cx(styles.iconContainer, styles.column, {
            [styles.active]: isActive,
          })}
          onClick={handleIconClick}
        >
          <Icons type={ICONS.LIKE_SONG_LIST} />
        </div> */}
        <div className={cx(styles.columnLast)}>
          <div
            className={cx(styles.iconContainer, styles.column, {
              [styles.active]: isActive,
            })}
            onClick={handleIconClick}
          >
            <Icons type={ICONS.LIKE_SONG_LIST} />
          </div>
          {fromScreen === "homePage" && (
            <div
              onClick={handleIgnore}
              className={cx(styles.lastColumn, {
                [styles.ignored]: get(
                  ignoredSongs,
                  id + currentPlaylistId,
                  false
                ),
              })}
            >
              <Icons type={ICONS.IGNORE_SONG} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SongListItemWithImage.propTypes = {
  continarClassName: propTypes.string,
  song: propTypes.object,
  isActive: propTypes.bool,
  onClick: propTypes.func,
  onIconClick: propTypes.func,
};

SongListItemWithImage.defaultProps = {
  continarClassName: "",
  song: {},
  isActive: false,
  onClick: noop,
  onIconClick: noop,
};

export default memo(SongListItemWithImage);
