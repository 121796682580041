import cx from "classnames";
import styles from "./circularProgressLoader.module.scss";

const CircularProgressLoader = ({ active, style, progress, max }) => {
  const progressPercentage = (progress / max) * 100;

  return (
    <span
      className={cx(styles.loader, { [styles.blackLoader]: active })}
      style={{ ...style, '--progress': progressPercentage }}
    ></span>
  );
};

export default CircularProgressLoader;