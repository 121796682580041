import React, { useState } from "react";

import Button from "../button";
import propTypes from "prop-types";
import cx from "classnames";
import { isEmpty, map, noop } from "lodash";

import styles from "./createSchedulePopUp.module.scss";
import ThumbNail from "../thumbNail";
import playlistStore from "../../zustand/playlist.store";
import { MEDIA_URL } from "../../axios";

const CreateSchedulePopUp = ({
  isOpen,
  setIsOpen,
  btnPlaceholder,
  isLoading,
  handleSuccess,
}) => {
  const { playlists } = playlistStore((state) => state);

  const [selectedPlaylist, setSelectedPlaylist] = useState(null);

  // console.log(isOpen);

  const onBackgroundClick = (e) => {
    if (e) e.stopPropagation();
    setIsOpen(false);
  };

  const handleClick = (item) => {
    setSelectedPlaylist(item?.id);
  };
  const handleContinueClick = (id) => {
    handleSuccess(selectedPlaylist);
  };

  return (
    <div className={cx([styles.popUpWrapper], { [styles.popUpOpen]: isOpen })}>
      <div className={styles.popUpBg} onClick={onBackgroundClick}></div>
      <div
        className={styles.popUpChildren}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.deleteEventPopUp}>
          <div className={styles.main}>
            <h2 className={styles.text2}>Select Playlist</h2>
            <div className={styles.scroll}>
              {map(playlists, (item) => {
                const { id, name, image, color, visibelePhoto } = item;
                const isSelected = selectedPlaylist === id;
                return (
                  <div
                    key={id}
                    className={cx(styles.container, {
                      [styles.selected]: isSelected,
                    })}
                    onClick={() => handleClick(item)}
                  >
                    <ThumbNail
                      name={name}
                      color={color}
                      className={cx(styles.thumbnailClassName)}
                      hasImage={!isEmpty(image)}
                      imgSrc={`${MEDIA_URL}/playlist_images/${image}`}
                      photoString={visibelePhoto}
                    />
                    <div className={styles.playlist}>
                      <p className={styles.text1}>{name}</p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={styles.btn}>
              <Button
                isLoading={isLoading}
                className={styles.button}
                onClick={handleContinueClick}
                disabled={isEmpty(selectedPlaylist)}
              >
                {btnPlaceholder}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CreateSchedulePopUp.propTypes = {
  isOpen: propTypes.bool,
  title: propTypes.string.isRequired,
  iconType: propTypes.string.isRequired,
  setIsOpen: propTypes.func.isRequired,
  btnPlaceholder: propTypes.string.isRequired,
  onSuccess: propTypes.func,
  id: propTypes.oneOfType([propTypes.string, propTypes.number]),
  onCancle: propTypes.func,
};

CreateSchedulePopUp.defaultProps = {
  isOpen: false,
  onSuccess: noop,
  id: null,
  onCancle: noop,
};

export default CreateSchedulePopUp;
