import { useState } from "react";
import propTypes from "prop-types";
import cx from "classnames";

import styles from "./thumbNail.module.scss";
import { isEmpty } from "lodash";
// import imageStore from "../../zustand/image.store";

const ThumbNail = ({
  name,
  color,
  className,
  imgSrc,
  photoString,
  // imageFileName,
}) => {
  // const { donwloadedImges } = imageStore((state) => ({
  //   donwloadedImges: state.donwloadedImges,
  // }));

  // const isImageDonwloaded = donwloadedImges?.[imageFileName] || false;

  const [isImageDonwloaded, setIsImageDonwloaded] = useState(false);

  return (
    <div
      className={cx(styles.thumbNail, className)}
      style={{ backgroundColor: isImageDonwloaded ? "transparent" : color }}
    >
      <div className={styles.imageContainer} style={{
        opacity: isImageDonwloaded ? 1 : 0,
        width: isImageDonwloaded ? "100%" : 0,
        height: isImageDonwloaded ? "100%" : 0,
      }} >
        <img
          src={imgSrc}
          alt="pending"
          crossOrigin="anonymous"
          loading="lazy"
          onLoad={() => setIsImageDonwloaded(true)}
          onError={() => setIsImageDonwloaded(false)}
        />
      </div>
      {(!isImageDonwloaded ? (!isEmpty(photoString)) ? (
        <img
          src={photoString}
          alt="img"
          style={{
            height: "100%",
            width: "100%",
            objectFit: "contain",
          }}
        />
      ) : (
        <p>{name[0]}</p>
      ) : <></>)}
    </div>
  );
};

ThumbNail.propTypes = {
  name: propTypes.string,
  color: propTypes.string,
  className: propTypes.string,
};

ThumbNail.defaultProps = {
  name: "",
  color: "#BC589A",
  className: "",
};

export default ThumbNail;
