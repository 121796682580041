/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import { filter, get, includes, isEmpty, map, max, size } from "lodash";
//

// import gsap from "gsap";

import { ICONS } from "../../constants/icons.constants";
import Icons from "../icons";
import { getFormattedTime } from "./musicPlayer.helpers";
import ThumbNail from "../thumbNail/ThumbNail";
import LoadingSkeleton from "../loadingSkeleton/LoadingSkeleton";
import ProgressBar from "../progressBar/ProgressBar";

import styles from "./musicPlayer.module.scss";
import musicPlayerStore from "../../zustand/musicPlayer.store";
// import socketStore from "../../zustand/socket.store";
import likedSongsStore from "../../zustand/likedSong.store";
import {
  addSongToFavorites,
  removeSongFromFavorites,
} from "../../actions/songs.actions";
// import socket from "../../socket";
import { socketServices } from "../../services/socket.service";
import playlistStore from "../../zustand/playlist.store";
import SchedulesStore from "../../zustand/Schedule.store";
import { ROUTES } from "../../constants/routes.constants";
import { useNavigate } from "react-router-dom";
// import songsStore from "../../zustand/songs.store";
import { io } from "socket.io-client";

import { getRandomColor, getToken } from "../../helpers/generel.helpers";
import { BASE_URL, MEDIA_URL } from "../../axios";
import userStore from "../../zustand/user.store";
import { doTokenRefresh, fetchCustomerData } from "../../actions/user.actions";
import { shuffleArray } from "../../helpers/music.helpers";
import { ignoreSong } from "../../actions/playlist.actions";
import ignoredSongsStore from "../../zustand/ignoreSong.store";
import { showToaster } from "../../helpers/toaster.helpers";
//

const TIME_OUT = 12;

const MusicPlayer = () => {
  const {
    songs,
    currentSongIndex,
    isLoading,
    setCurrentSongIndex,
    isPlaying,
    setIsPlaying,
    playList,
    setSongs,
    setPlayList,
    setAudioRef1,
    setAudioRef2,
    setCurrentAudioRefIndex,
    toggleIsShuffle,
    isShuffle,
    searchedSong,
    setPlayListFromScoket,
    playSearchSong,
    beforeShuffleSongs,
  } = musicPlayerStore((state) => ({
    songs: state.songs,
    currentSongIndex: state.currentSongIndex,
    isLoading: state.isLoading,
    setCurrentSongIndex: state.setCurrentSongIndex,
    isPlaying: state.isPlaying,
    setIsPlaying: state.setIsPlaying,
    playList: state.playList,
    setSongs: state.setSongs,
    setPlayList: state.setPlayList,
    setAudioRef1: state.setAudioRef1,
    setAudioRef2: state.setAudioRef2,
    setCurrentAudioRefIndex: state.setCurrentAudioRefIndex,
    toggleIsShuffle: state.toggleIsShuffle,
    isShuffle: state.isShuffle,
    searchedSong: state.searchedSong,
    setPlayListFromScoket: state.setPlayListFromScoket,
    playSearchSong: state.playSearchSong,
    beforeShuffleSongs: state.beforeShuffleSongs,
  }));
  const {
    songs: likedSongs,
    addSong: addLikedSong,
    deleteSong: deleteLikedSong,
  } = likedSongsStore((state) => ({
    songs: state.songs,
    addSong: state.addSong,
    deleteSong: state.deleteSong,
  }));
  const { isAuthenticated, userOffline } = userStore((state) => ({
    isAuthenticated: state.isAuthenticated,
    userOffline: state.userOffline,
  }));
  const isShuffleRef = useRef(false);
  //
  const { schedulesDone, markScheduleDone } = SchedulesStore((state) => ({
    schedulesDone: state.schedulesDone,
    markScheduleDone: state.markScheduleDone,
  }));
  //
  const {
    playlists,
    // setIgnoreSong,
    addLikedSongToPlaylist,
    removeUnLikedSongFromPlaylist,
    setPlaylists,
  } = playlistStore((state) => ({
    playlists: state.playlists,
    // setIgnoreSong: state.setIgnoreSong,
    addLikedSongToPlaylist: state.addLikedSongToPlaylist,
    removeUnLikedSongFromPlaylist: state.removeUnLikedSongFromPlaylist,
    setPlaylists: state.setPlaylists,
  }));

  const {
    songs: ignoredSongs,
    addSong: addIgnoreSong,
    deleteSong: removeIgnoreSong,
  } = ignoredSongsStore();
  //
  //
  const workerRef = useRef(null);
  //
  const currentSongIdx = useRef(0);
  const songOffset = useRef(1);

  // for progress bar
  const progressBarRef = useRef(null);

  // for volume bar
  const volumeBarRef = useRef(null);

  // audio refs
  const audioRef1 = useRef(null);
  const audioRef2 = useRef(null);
  const currentAudioRefIndex = useRef(1);
  const getCurrentAudioRef = () =>
    currentAudioRefIndex.current === 1 ? audioRef1 : audioRef2;
  const getOtherAudioRef = () =>
    currentAudioRefIndex.current === 2 ? audioRef1 : audioRef2;
  const isFirstSongOfPlaylist = useRef(true);
  //
  const isPlayingRef = useRef(false);
  // console.log("isPlayingRef", isPlayingRef.current, isPlaying);

  // refs for song time
  const currentTimeRef = useRef(null);
  const totalTimeRef = useRef(null);

  const isTimeChaneging = useRef(false);
  const isVolumeChanging = useRef(false);
  const isFadeOutStarted = useRef(false);
  const isRecentlyShuffled = useRef(false);
  const isRecentlyShuffled2 = useRef(false);
  const isShuffleFromInitialState = useRef(true);
  //
  const navigate = useNavigate();
  //
  const [isSongLoading, setIsSongLoading] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isIgnore, setIsIgnore] = useState(false);
  //
  const songsRef = useRef(songs);
  const playlistsRef = useRef(playlists);
  const playListRef = useRef(playList);
  const scheduleDoneRef = useRef(schedulesDone);
  const beforeShuffle = useRef(null);
  const searchedSongRef = useRef(searchedSong);
  const isPlaylistChanged = useRef(false);
  const socketRef = useRef(null);
  const tokenCounter = useRef(0);
  const scheduleCounter = useRef(0);
  //
  // const socketSongRef = useRef({
  //   playlist_id: "",
  //   song_id: "",
  // });

  // ? these are raw inputs only updated by the user
  const volume = useRef(1);
  // const progress = useRef(0);

  console.log("MusicPlayer");

  const handleLikeClick = async () => {
    if (userOffline) {
      return showToaster(
        "error",
        "You are offline, please check your internet connection"
      );
    }
    const song = searchedSong ? searchedSong : songs[currentSongIndex];
    const { index = "1.0", id } = song;
    const favorite = likedSongs?.[id];

    let isSuccessful = false;
    const isAlreadyLiked = favorite;

    if (isAlreadyLiked) {
      deleteLikedSong(id);
      removeUnLikedSongFromPlaylist(song);
    } else {
      addLikedSong(id);
      addLikedSongToPlaylist(song);
    }

    if (isAlreadyLiked) {
      isSuccessful = await removeSongFromFavorites({
        song_id: id,
        song_name: song.title,
        artist_name: song.artist,
      });
    } else {
      isSuccessful = await addSongToFavorites({
        song_id: id,
        index,
        song_name: song.title,
        artist_name: song.artist,
      });
    }

    if (!isSuccessful) {
      if (isAlreadyLiked) {
        addLikedSong(id);
      } else {
        deleteLikedSong(id);
      }
      return;
    }
  };

  const handleIgnoreClick = async (e) => {
    e.stopPropagation();
    if (userOffline) {
      return showToaster(
        "error",
        "You are offline, please check your internet connection"
      );
    }
    const song = searchedSong ? searchedSong : songs[currentSongIndex];
    const { id } = song;

    const currentPlaylistId = playList?.id;
    const idForIngoreSong = id + currentPlaylistId;
    const newIgnore = get(ignoredSongs, idForIngoreSong, false) ? false : true;

    if (newIgnore) {
      addIgnoreSong(idForIngoreSong);
    } else {
      removeIgnoreSong(idForIngoreSong);
    }

    // // setIsIgnore(newIgnore);
    const isSuccess = await ignoreSong(currentPlaylistId, id, {
      ignore: newIgnore,
    });

    if (!isSuccess) {
      if (newIgnore) {
        removeIgnoreSong(idForIngoreSong);
      } else {
        addIgnoreSong(idForIngoreSong);
      }
    }
  };

  const handleSuffle = () => {
    isRecentlyShuffled.current = true;
    isRecentlyShuffled2.current = true;
    isShuffleFromInitialState.current = false;
    socketServices.doShuffle(!isShuffle);
    if (!isShuffle) {
      beforeShuffle.current = songs;
      const shuffledSongs = shuffleArray([...songs], currentSongIdx.current);
      setSongs(shuffledSongs, currentSongIdx.current);
      toggleIsShuffle(true);
      isShuffleRef.current = true;
    } else {
      setSongs(beforeShuffle.current, currentSongIdx.current);
      toggleIsShuffle(false);
      isShuffleRef.current = false;
    }
    setIsPlaying(true);
  };
  //
  useEffect(() => {
    if (isShuffleFromInitialState.current) return;
    if (isShuffle !== isShuffleRef.current) {
      if (isShuffle) {
        beforeShuffle.current = songs;
        const shuffledSongs = shuffleArray([...songs], currentSongIdx.current);
        setSongs(shuffledSongs);
        isShuffleRef.current = true;
      } else {
        setSongs(beforeShuffle.current);
        isShuffleRef.current = false;
      }
      setIsPlaying(true);
    }
  }, [isShuffle, songs]);
  //

  // const handleSongChagne = (offset) => () => {
  //   if (isSongLoading) return;
  //   if (currentSongIndex + offset < 0) return;
  //   if (currentSongIndex + offset >= size(songs)) return;
  //   const nextSongIndex = (currentSongIndex + offset) % size(songs);
  //   setCurrentSongIndex(nextSongIndex);
  // };

  const findNextValidSong = (offset) => {
    const songsSize = size(songsRef.current);
    let nextSongIndex = (currentSongIdx.current + offset) % songsSize;

    // Handle negative index case
    if (nextSongIndex < 0) {
      nextSongIndex = songsSize + nextSongIndex;
    }

    // Find the next valid song that is not ignored
    while (songsRef.current[nextSongIndex]?.ignore) {
      nextSongIndex = (nextSongIndex + offset) % songsSize;
      if (nextSongIndex < 0) {
        nextSongIndex = songsSize + nextSongIndex;
      }
    }

    return nextSongIndex;
  };

  const handleSongChagne = (offset) => () => {
    if (isSongLoading) return;

    let nextSongIndex = findNextValidSong(offset);

    setCurrentSongIndex(nextSongIndex);
  };

  const togglePlayButton = async () => {
    console.log("From togglePlayButton");
    const playingState = isPlaying;
    if (!isPlaying && isFirstSongOfPlaylist.current)
      startSwitchFadeInEffect(volume.current);

    setIsPlaying(!isPlaying);

    if (userOffline) return;
    try {
      if (playingState) {
        await socketServices.pauseSong();
      } else {
        const payload = {
          song_id: songs?.[currentSongIndex]?.id,
        };
        if (playList) {
          payload.playlist_id = playList.id;
        }
        await socketServices.changeSong(payload);
      }
    } catch (error) {}
  };

  const onSongListChange = () => {
    // const currentAudioRef = getCurrentAudioRef();
    setIsSongLoading(true);
    if (isPlayingRef.current) {
      getCurrentAudioRef()
        .current.play()
        .then(() => setIsSongLoading(false))
        .catch(() => setIsSongLoading(false));
      getCurrentAudioRef().current.volume = 0;
      startSwitchFadeInEffect(volume.current);
    } else {
      setIsSongLoading(false);
      isFirstSongOfPlaylist.current = true;
      getCurrentAudioRef().current.volume = 0;
    }
  };

  const startSwitchFadeInEffect = (endVal) => {
    isFirstSongOfPlaylist.current = false;
    if (workerRef.current) {
      workerRef.current.active.postMessage({
        type: "SWITCH_FADE_START",
        val: endVal,
        timeOut: TIME_OUT,
      });
      isVolumeChanging.current = false;
    }
  };

  const startFadeOnUpdate = (v) => {
    if (!isVolumeChanging.current) getCurrentAudioRef().current.volume = v;
  };

  const startFadeOnStart = () => {
    setIsSongLoading(true);
    currentAudioRefIndex.current = (currentAudioRefIndex.current % 2) + 1;
    // check if we have song from search then don;t change the index
    if (isPlaylistChanged.current) {
      isPlaylistChanged.current = false;
    } else if (!searchedSongRef.current) {
      const nextSongIndex = findNextValidSong(songOffset.current);
      console.log(nextSongIndex, "nextSongIndex");
      currentSongIdx.current = nextSongIndex;
    } else {
      searchedSongRef.current = null;
      isPlaylistChanged.current = false;
    }

    songOffset.current = 1;

    if (isPlayingRef.current) {
      getCurrentAudioRef()
        .current.play()
        .then(() => {
          setIsSongLoading(false);
        })
        .catch((e) => {
          setIsSongLoading(false);
        });
    } else {
      setIsSongLoading(false);
      setIsPlaying(true);
    }
    isVolumeChanging.current = false;
  };

  const startFadeOnComplete = () => {
    isFadeOutStarted.current = false;
    getOtherAudioRef().current.src =
      songsRef.current?.[findNextValidSong(1) % size(songsRef.current)]?.url;
  };

  // ! replace this wiht serviec worker event
  const startFadeInEffect = (endVal) => {
    if (workerRef.current) {
      workerRef.current.active.postMessage({
        type: "START_FADE",
        val: endVal,
        timeOut: TIME_OUT,
      });
    }
  };

  const fadeOutOnUpdate = (v) => {
    if (!isVolumeChanging.current) getOtherAudioRef().current.volume = v;
  };

  // ! replace this wiht serviec worker event
  const startFadeOutEffect = (dif, startVal) => {
    if (workerRef.current) {
      getOtherAudioRef().current.src =
        songsRef.current?.[
          findNextValidSong(songOffset.current) % size(songsRef.current)
        ]?.url;
      workerRef.current.active.postMessage({
        type: "FADE_OUT",
        val: startVal,
        timeOut: TIME_OUT,
        diff: dif,
      });
    }
  };

  const counterRef = useRef(0);
  const nonUiLifeCycle = () => {
    // ? testing
    // nonUiLifeCycleCountRef.current -= 1;
    counterRef.current += 1;
    // console.log("counterRef.current", counterRef.current);
    const currentAudioRef = getCurrentAudioRef();
    const dif =
      currentAudioRef.current?.duration - currentAudioRef.current?.currentTime;

    // console.log("I am nonUiLifeCycle", dif, isFadeOutStarted.current);
    if (dif < TIME_OUT && !isFadeOutStarted.current) {
      // console.log("Song Changed Automatically");
      isFadeOutStarted.current = true;
      startFadeOutEffect(dif, volume.current);
      startFadeInEffect(volume.current);
    }
    //
    tokenCounter.current += 500;
    scheduleCounter.current += 500;
    if (tokenCounter.current >= 8 * 60 * 1000) {
      tokenCounter.current = 0;
      handleTokenRefresh();
    }
    if (scheduleCounter.current >= 1000) {
      scheduleCounter.current = 0;
      scheduleCycle();
    }

    workerRef.current.active.postMessage({
      type: "START_NON_UI_CYCLE",
    });
  };

  const uiLifeCycle = () => {
    const currentAudioRef = getCurrentAudioRef();

    if (!currentAudioRef.current) return;
    if (!currentAudioRef.current.src) return;

    currentTimeRef.current.innerHTML = getFormattedTime(
      currentAudioRef.current?.currentTime
    );
    totalTimeRef.current.innerHTML = getFormattedTime(
      currentAudioRef.current?.duration
    );

    if (!isTimeChaneging.current) {
      let progressValue =
        currentAudioRef.current.currentTime / currentAudioRef.current.duration;
      if (isNaN(progressValue)) progressValue = 0;
      progressBarRef.current.value = progressValue;
    }

    //* update song thumbnail UI
    // console.log(currentSongIdx.current, ,"currentSongIdx.current");
    if (currentSongIndex !== currentSongIdx.current) {
      // console.log("Song Changed From UI cycle");
      setCurrentSongIndex(currentSongIdx.current);
    }
  };

  const scheduleCycle = () => {
    if (playlistsRef.current && playlistsRef.current.length) {
      for (let i = 0; i < playlistsRef.current.length; i++) {
        const schedules = playlistsRef.current[i].schedule;
        if (!schedules?.length) continue;
        for (let j = 0; j < schedules.length; j++) {
          const schedule = schedules[j];
          const daysArray = schedule.days;
          const time = schedule.time; // this will return 00:00 format
          // today
          const dayToday = new Date().getDay(); // this will return 0 for sunday, 1 for monday and so on
          // get current time
          const timeToday = new Date().toLocaleTimeString("en-US", {
            hour12: false,
            hour: "numeric",
            minute: "numeric",
          }); // this will return 00:00 format
          if (daysArray.includes(dayToday) && timeToday === time) {
            const uniqeStoringId = `${playlistsRef.current[i].id}-${dayToday}-${time}`;
            if (!scheduleDoneRef.current[uniqeStoringId]) {
              // play this playlist
              setSongs(playlistsRef.current[i].songs);
              setPlayList({
                id: playlistsRef.current[i].id,
              });
              setIsPlaying(true);
              navigate(ROUTES.HOME);
              //
              markScheduleDone(uniqeStoringId);
            }
            return;
          }
        }
      }
    }
    workerRef.current.active.postMessage({
      type: "START_SCHEDULE_CYCLE",
    });
  };

  // // * Socket Song change trigger
  // useEffect(() => {
  //   if (
  //     song_changed.song_id &&
  //     song_changed.playlist_id
  //     // &&
  //     // socketSongRef.song_id !== song_changed.song_id &&
  //     // socketSongRef.playlist_id !== song_changed.playlist_id
  //   ) {
  //     socketSongRef.song_id = song_changed.song_id;
  //     socketSongRef.playlist_id = song_changed.playlist_id;
  //     //
  //     const playListId = song_changed.playlist_id;
  //     const songId = song_changed.song_id;

  //     console.log(song_changed, "song_changed");
  //     if (song_changed.playlist_id) {
  //       const playlistFromSocket = playlists.find((p) => p.id === playListId);
  //       console.log(playlistFromSocket, "playlistFromSocket");

  //       if (playlistFromSocket) {
  //         const songsAfterIgnore = playlistFromSocket.songs.filter(
  //           (s) => !s.ignore
  //         );
  //         let songIndex = songsAfterIgnore.findIndex((s) => s.id === songId);

  //         console.log(songIndex, "songIndex");

  //         if (songIndex === -1) {
  //           songIndex = 0; // fallback
  //         }

  //         //* if playlist is changed
  //         if (playlistFromSocket.id !== playList?.id) {
  //           setPlayList({
  //             id: playlistFromSocket.id,
  //           });
  //           setSongs(songsAfterIgnore, songIndex);
  //         }
  //         //* if playlist is same
  //         else {
  //           //* if song is changed
  //           if (songIndex !== currentSongIdx.current) {
  //             // setSongs(playlistFromSocket.songs, songIndex);
  //             setCurrentSongIndex(songIndex);
  //           } else {
  //             togglePlayButton();
  //           }
  //         }

  //         // if (songIndex !== -1 && currentSongIdx !== songIndex)
  //         // setCurrentSongIndex(songIndex);
  //         // currentSongIdx.current = songIndex;
  //       }
  //     }
  //     // else {
  //     //   const songIndex = allSongs.findIndex((s) => s.id === songId);
  //     //   if (playList?.id) {
  //     //     setSongs(allSongs);
  //     //     setPlayList(null);
  //     //   }
  //     //   if (songIndex !== -1 && currentSongIdx !== songIndex)
  //     //     setCurrentSongIndex(songIndex);
  //     // }
  //   }
  // }, [song_changed, playlists, playList, currentSongIdx]);

  useEffect(() => {
    const id = setInterval(() => {
      uiLifeCycle();
    }, 500);
    return () => {
      clearInterval(id);
    };
  }, [currentSongIndex]);

  useEffect(() => {
    setAudioRef1(audioRef1.current);
    setAudioRef2(audioRef2.current);
    setCurrentAudioRefIndex(currentAudioRefIndex);
  }, []);

  useEffect(() => {
    // * initialize song
    songsRef.current = songs;
    const currentAudioRef = getCurrentAudioRef();

    if (isRecentlyShuffled.current) {
      isRecentlyShuffled.current = false;
      const otherAudioRef = getOtherAudioRef();
      const nextSongINdex = findNextValidSong(1) % size(songs);
      otherAudioRef.current.src = songs[nextSongINdex]?.url;
      return;
    }

    if (currentAudioRef.current?.src) {
      // * this line is responsible for playing select song from playlist
      // * playList dependecy is for this logic
      const playlistSongIndex = playList?.songIndex || 0;
      currentSongIdx.current = playlistSongIndex;

      isPlaylistChanged.current = true;
      const otherAudioRef = getOtherAudioRef();
      otherAudioRef.current.src = songs[playlistSongIndex]?.url;
      const dif =
        currentAudioRef.current?.duration -
        currentAudioRef.current?.currentTime;
      if (workerRef.current) {
        workerRef.current.active.postMessage({
          type: "FADE_OUT",
          val: volume.current,
          timeOut: TIME_OUT,
          diff: dif,
        });
      }
      startFadeInEffect(volume.current);
      return;
    }

    //* initializeing audio refs
    if (audioRef1.current && !isEmpty(songs)) {
      getCurrentAudioRef().current.src = songs?.[currentSongIdx.current]?.url;
      getOtherAudioRef().current.src =
        songs?.[findNextValidSong(1) % size(songs)]?.url;
      // togglePlayButton();
      onSongListChange();
    }
  }, [songs, playList]);

  // * on song change from outside the music player
  useEffect(() => {
    if (currentSongIndex !== currentSongIdx.current) {
      songOffset.current = currentSongIndex - currentSongIdx.current;
      const dif = Math.min(
        TIME_OUT,
        getCurrentAudioRef().current.duration -
          getCurrentAudioRef().current.currentTime
      );
      isFadeOutStarted.current = true;
      startFadeOutEffect(dif, volume.current);
      startFadeInEffect(volume.current);
    }
  }, [currentSongIndex]);

  const [addedTitle, setAddedTitle] = useState("");
  // ? service - worker
  useEffect(() => {
    const doEvents = (event) => {
      // console.log(event.data.type);
      if (event.data.type === "NON_UI_CYCLE") nonUiLifeCycle();
      else if (event.data.type === "START_FADE_UPDATE")
        startFadeOnUpdate(event.data.val);
      else if (event.data.type === "START_FADE_START") startFadeOnStart();
      else if (event.data.type === "START_FADE_COMPLETE") startFadeOnComplete();
      else if (event.data.type === "FADE_OUT_UPDATE")
        fadeOutOnUpdate(event.data.val);
    };
    //
    // caches.keys().then(function (names) {
    //   for (let name of names) caches.delete(name);
    //
    // serviceWorkerRegistration.register();

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then((worker) => {
        workerRef.current = worker;
        worker.active.postMessage({
          type: "START_NON_UI_CYCLE",
        });
      });

      //
      navigator.serviceWorker.addEventListener("message", doEvents);
    }
    //
    // });

    return () => {
      navigator.serviceWorker.removeEventListener("message", doEvents);
      // unregister worker

      // navigator.serviceWorker.getRegistrations().then((registrations) => {
      //   for (let registration of registrations) {
      //     registration.unregister();
      //   }
      // });
    };
  }, []);

  useEffect(() => {
    const id = setTimeout(() => {
      setAddedTitle(".");
      // reduce olume by 1%
      getCurrentAudioRef().current.volume = Math.max(
        0,
        getCurrentAudioRef().current.volume - 0.01
      );
      setTimeout(() => {
        setAddedTitle("");
        // increase volume by 1%
        getCurrentAudioRef().current.volume = Math.min(
          1,
          getCurrentAudioRef().current.volume + 0.01
        );
      }, 1000);
    }, 35000);

    return () => {
      clearTimeout(id);
    };
  }, []);

  // * play/pause
  useEffect(() => {
    isPlayingRef.current = isPlaying;
    const currentAudioRef = getCurrentAudioRef();
    if (!isPlaying) {
      audioRef1.current.pause();
      audioRef2.current.pause();
    } else {
      setIsSongLoading(true);
      // set the volume to max
      currentAudioRef.current.volume = volume.current;
      currentAudioRef.current
        .play()
        .then(() => {
          setIsSongLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsSongLoading(false);
        });
    }
  }, [isPlaying]);
  //

  const handleSongChaneFromSocket = (data) => {
    //* new code
    const { playlist_id: playListId, song_id: songId, song_name } = data;
    const playlistFromSocket = playlistsRef.current.find(
      (p) => p.id === playListId
    );
    if (playlistFromSocket) {
      const songsAfterIgnore = playlistFromSocket.songs.filter(
        (s) => !s.ignore
      );
      let songIndex = songsAfterIgnore.findIndex((s) => s.id === songId);
      if (songIndex === -1) {
        songIndex = 0; // fallback
      }

      //* if playlist is changed
      if (playlistFromSocket.id !== playListRef.current?.id) {
        setPlayListFromScoket(
          {
            id: playlistFromSocket.id,
            songIndex,
          },
          playlistFromSocket.songs,
          songIndex
        );
      }
      //* if playlist is same
      else {
        //* if song is changed
        if (songIndex !== currentSongIdx.current) {
          setCurrentSongIndex(songIndex);
        } else {
          togglePlayButton();
        }
      }
    } else {
      // * write logic here for for only song id
      const searchedSongFromPlayer = {
        id: songId,
        title: song_name,
        fileName: songId + ".mp3",
      };
      // const searchSongFromSocket = JSON.parse(songId);
      playSearchSong(searchedSongFromPlayer);
    }
  };

  const handleRefreshPlaylists = async () => {
    if (userOffline) return;
    try {
      const customerData = await fetchCustomerData();
      const { playlists } = customerData;
      setPlaylists(playlists);
    } catch (error) {
      console.log("error", error);
    }
  };

  const connectSocket = () => {
    socketRef.current = io(BASE_URL, {
      reconnect: true,
      transports: ["websocket"],
      query: { token: getToken() }, // used for auth
    });
    socketRef.current.on("connect", (data, error) => {
      socketRef.current.on("message", (data) => {
        const { type } = data;
        console.log(type, data, "this is from event");
        if (type === "shuffle") {
          if (!!data.data.shuffle !== isShuffleRef.current)
            toggleIsShuffle(!!data.data.shuffle);
        } else if (type === "song_changed") {
          handleSongChaneFromSocket(data.data);
        } else if (type === "volume_changed") {
          try {
            let volumeSocket = parseInt(data.data.volume);
            if (volumeSocket >= 0 && volumeSocket <= 100) {
              volumeSocket = `${volumeSocket / 100}`;
              getCurrentAudioRef().current.volume = volumeSocket;
              getOtherAudioRef().current.volume = 0;
              isVolumeChanging.current = true;
              volume.current = volumeSocket;
              volumeBarRef.current.value = volumeSocket;
            }
          } catch (err) {
            console.log(err);
          }
        } else if (type === "pause") {
          if (isPlayingRef.current) setIsPlaying(false);
        } else if (type === "song_liked") {
          const { song_id, status, song_name, artist_name } = data?.data || {};

          const songToAdd = {
            id: song_id,
            title: song_name,
            artist: artist_name,
            fileName: song_id + ".mp3", // "01HBD8NZDWKNAXV8SDCYTD4QGN.mp3",
            imageFileName: song_id + ".jpg", // "01HBD8NZDWKNAXV8SDCYTD4QGN.jpg",
            index: "0.00",
            ignore: false,
            favorite: true,
            url: MEDIA_URL + "/songs/" + song_id + ".mp3", //  "https://dev.4-play.io/songs/01HBD8NZDWKNAXV8SDCYTD4QGN.mp3",
            color: getRandomColor(),
          };

          // const songToAdd = songs?.find((s) => s.id === song_id);
          if (!status) {
            deleteLikedSong(song_id);
            removeUnLikedSongFromPlaylist(songToAdd);
          } else {
            addLikedSong(song_id);
            addLikedSongToPlaylist(songToAdd);
          }
        } else if (type === "refresh_playlist") {
          handleRefreshPlaylists();
        } else if (type === "song_ignored") {
          // update ignore song
          const { song_id, status, playlist_id } = data?.data || {};
          if (status) {
            addIgnoreSong(song_id + playlist_id);
          } else {
            removeIgnoreSong(song_id + playlist_id);
          }
        }
      });
    });
  };

  const handleTokenRefresh = async () => {
    if (userOffline) return;
    await doTokenRefresh();
    disconnectSocket();
    connectSocket();
  };

  const disconnectSocket = () => {
    socketRef.current.off("connect");
    socketRef.current.off("message");
    socketRef.current.disconnect();
  };

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     handleTokenRefresh();
  //   }, 8 * 60 * 1000);

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      // stop music
      audioRef1.current.pause();
      audioRef2.current.pause();
    }
  }, [isAuthenticated]);

  // * socket
  useEffect(() => {
    if (userOffline) {
      disconnectSocket();
      return;
    }
    connectSocket();
    return disconnectSocket;
  }, [isAuthenticated, userOffline]);

  // * send song change event to socket
  useEffect(() => {
    if (isRecentlyShuffled2.current) {
      isRecentlyShuffled2.current = false;
      return;
    }

    const payload = {};
    if (!isEmpty(songs)) {
      payload.song_id = songs?.[currentSongIndex]?.id;
    }
    if (playList) {
      payload.playlist_id = playList?.id;
    }
    if (searchedSong) {
      payload.song_id = searchedSong?.id;
      payload.song_name = searchedSong?.title;
      delete payload.playlist_id;
    }
    if (!isEmpty(payload) && !userOffline) {
      // console.log(payload, "sending socket event");
      try {
        socketServices.changeSong(payload);
      } catch (error) {}
    }
  }, [currentSongIndex, songs, playList, searchedSong, userOffline]);

  //* convert state to ref
  useEffect(() => {
    playlistsRef.current = playlists;
  }, [playlists]);

  useEffect(() => {
    scheduleDoneRef.current = schedulesDone;
  }, [schedulesDone]);

  useEffect(() => {
    playListRef.current = playList;
  }, [playList]);

  useEffect(() => {
    beforeShuffle.current = beforeShuffleSongs;
  }, [beforeShuffleSongs]);

  // handle Search Song Click
  useEffect(() => {
    if (!searchedSong) return;
    searchedSongRef.current = searchedSong;
    const currentAudioRef = getCurrentAudioRef();
    const otherAudioRef = getOtherAudioRef();
    otherAudioRef.current.src = searchedSong?.url;
    const dif =
      currentAudioRef.current?.duration - currentAudioRef.current?.currentTime;
    if (workerRef.current && isPlayingRef.current) {
      workerRef.current.active.postMessage({
        type: "FADE_OUT",
        val: volume.current,
        timeOut: TIME_OUT,
        diff: dif,
      });
    }
    startFadeInEffect(volume.current);
  }, [searchedSong]);

  useEffect(() => {
    const song = searchedSong ? searchedSong : songs[currentSongIndex];
    const id = get(song, "id", "");
    const isAlreadyIgnored = includes(ignoredSongs, id);
    setIsIgnore(isAlreadyIgnored);
  }, [songs, currentSongIndex, ignoredSongs]);

  // if (isEmpty(songs) && !isLoading) return null;

  //add a keyboard event listener detect when the user presses the space bar
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.code === "Space") {
        togglePlayButton();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isPlaying]);

  return (
    <>
      <audio
        ref={audioRef1}
        controls
        style={{ display: "none" }}
        crossOrigin="anonymous"
        preload="auto"
      />
      <audio
        ref={audioRef2}
        controls
        style={{ display: "none" }}
        crossOrigin="anonymous"
        preload="auto"
      />
      {isLoading ? (
        <div className={styles.container}>
          <LoadingSkeleton isSchedule />
        </div>
      ) : (
        <div
          className={styles.container}
          onClick={() => {}}
          style={{
            pointerEvents: isSongLoading || isEmpty(songs) ? "none" : "all",
          }}
        >
          <div className={styles.imageBox}>
            <ThumbNail
              name={
                searchedSong
                  ? searchedSong.title
                  : songs?.[currentSongIndex]?.title
              }
              color={
                searchedSong
                  ? searchedSong.color
                  : songs?.[currentSongIndex]?.color
              }
              imgSrc={`${MEDIA_URL}/songs_images/${songs[currentSongIndex]?.imageFileName}`}
              hasImage={songs?.[currentSongIndex]?.imageFileName}
              imageFileName={songs?.[currentSongIndex]?.imageFileName}
            />
            <div className={styles.extraInfo}>
              <p className={styles.songName}>
                {searchedSong
                  ? searchedSong.title
                  : songs?.[currentSongIndex]?.title}
              </p>
              <p className={styles.writer}>
                {searchedSong
                  ? searchedSong.artist + addedTitle
                  : songs?.[currentSongIndex]?.artist + addedTitle}
              </p>
            </div>
            <div
              className={cx(styles.likedIcon, {
                [styles.activeLike]:
                  likedSongs?.[songs?.[currentSongIndex]?.id],
              })}
              onClick={handleLikeClick}
            >
              <Icons type={ICONS.LIKE_SONG_LIST} />
            </div>
            <div
              className={cx(styles.likedIcon, {
                [styles.activeIgnore]: get(
                  ignoredSongs,
                  songs?.[currentSongIndex]?.id + playList?.id,
                  false
                ),
              })}
              onClick={handleIgnoreClick}
            >
              <Icons type={ICONS.IGNORE_SONG} />
            </div>
          </div>

          <div className={styles.musicBox}>
            <div className={styles.iconBox}>
              <div
                style={{ transform: "scale(1.3)" }}
                className={styles.icon}
                onClick={handleSuffle}
              >
                <Icons
                  type={ICONS.SHUFFLE}
                  fill={isShuffle ? "#7442C7" : "#ffffff"}
                />
              </div>
              <div className={styles.icon} onClick={handleSongChagne(-1)}>
                <Icons type={ICONS.PREVIOUS} />
              </div>
              <div
                id={"play_button"}
                onClick={togglePlayButton}
                className={styles.icon}
              >
                {isPlaying ? (
                  <Icons type={ICONS.PLAY} />
                ) : (
                  <Icons type={ICONS.PAUSE} />
                )}
              </div>
              <div
                className={cx(styles.icon, styles.rotate)}
                onClick={handleSongChagne(1)}
              >
                <Icons type={ICONS.PREVIOUS} />
              </div>
              <div style={{ display: "none" }} className={styles.icon}>
                <Icons type={ICONS.REPEAT} />
              </div>
            </div>

            <div className={styles.barBox}>
              <p ref={currentTimeRef} className={styles.barTime}>
                {"00:00"}
              </p>
              <ProgressBar
                ref={progressBarRef}
                className={styles.musicBar}
                onChange={(e) => {
                  isTimeChaneging.current = true;
                }}
                onMouseUp={(e) => {
                  getCurrentAudioRef().current.currentTime =
                    getCurrentAudioRef().current.duration * e.target.value;
                  isTimeChaneging.current = false;
                  if (isFadeOutStarted.current) {
                    isVolumeChanging.current = true;
                    getCurrentAudioRef().current.volume =
                      volumeBarRef.current.value;
                    getOtherAudioRef().current.volume = 0;
                    isFadeOutStarted.current = false;
                  }
                }}
              />

              <p
                ref={totalTimeRef}
                className={styles.barTime}
                style={{ marginLeft: "0.8rem" }}
              >
                {"00:00"}
              </p>
            </div>
          </div>

          <div className={styles.volume}>
            <div
              className={styles.VolumeIcon}
              onClick={() => {
                if (!isMuted) {
                  audioRef1.current.muted = true;
                  audioRef2.current.muted = true;
                } else {
                  audioRef1.current.muted = false;
                  audioRef2.current.muted = false;
                }
                setIsMuted((prev) => !prev);
              }}
            >
              {!isMuted ? (
                <Icons type={ICONS.VOLUME} />
              ) : (
                <Icons type={ICONS.MUTE} />
              )}
            </div>
            <div className={styles.volumeBar}>
              <ProgressBar
                ref={volumeBarRef}
                onChange={(e) => {
                  getCurrentAudioRef().current.volume = e.target.value;
                  getOtherAudioRef().current.volume = 0;
                  isVolumeChanging.current = true;
                  volume.current = e.target.value;
                }}
                onMouseUp={(e) => {
                  try {
                    socketServices.vonlumeChange({
                      volume: parseInt(e.target.value * 100),
                    });
                  } catch (error) {}
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MusicPlayer;

// ! Things for noar
//* 1. next/prev click while trasitions
//* 2. next/prev transition?
//* 3. first song start transition?
