import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";
import propTypes from "prop-types";
import { debounce, map, noop } from "lodash";

import SongListItem from "./songListItem/songListItem";

import LoadingSkeleton from "../loadingSkeleton/LoadingSkeleton";
import songsStore from "../../zustand/songs.store";
import musicPlayerStore from "../../zustand/musicPlayer.store";

import SearchInput from "./SearchInput/SearchInput";

import styles from "./searchBar.module.scss";
import { fetchSongs } from "../../actions/songs.actions";
import userStore from "../../zustand/user.store";
import { showToaster } from "../../helpers/toaster.helpers";

const SearchBar = (props) => {
  const {
    containerClassName,
    songsListContainerClassName,
    songNameClassName,
    songArtistClassName,
    contentContianerClassName,
    hasBtn,
    onBtnClick,
  } = props;

  const { songs: initialSongs, isLoading } = songsStore((state) => ({
    songs: state.songs,
    isLoading: state.isLoading,
  }));
  const { playSearchSong } = musicPlayerStore((state) => ({
    playSearchSong: state.playSearchSong,
  }));

  const [isActive, setIsActive] = useState(false);
  const [songs, setSongs] = useState(initialSongs);

  const { userOffline } = userStore((state) => ({
    userOffline: state.userOffline,
  }));

  const handleInputFocus = () => {
    setIsActive(true);
  };

  const handlebackDropClick = () => {
    setIsActive(false);
  };

  const handleSongItemClick = (song) => () => {
    playSearchSong(song);
    handlebackDropClick();
  };

  const handleSearchKeyChange = (e) => {
    const { value } = e.target;
    functionWithDebounce(value);
  };

  const functionToRun = async (val) => {
    if (userOffline) {
      return showToaster(
        "error",
        "You are offline, please check your internet connection"
      );
    }
    const songs = await fetchSongs(val);
    setSongs(songs);
  };

  const functionWithDebounce = useCallback(
    debounce((val) => functionToRun(val), 400),
    []
  );

  useEffect(() => {
    setSongs(initialSongs);
  }, [initialSongs]);

  return (
    <>
      <div
        className={cx(styles.container, containerClassName, {
          [styles.activeBox]: isActive,
        })}
      >
        <SearchInput
          isActive={isActive}
          onFocus={handleInputFocus}
          // searchKey={searchKey}
          onChange={handleSearchKeyChange}
        />
        {isActive && (
          <div
            className={cx(styles.itemContainer, songsListContainerClassName)}
          >
            {!isLoading ? (
              map(songs, (song, index) => {
                return (
                  <SongListItem
                    key={song.id + "searcha bar song"}
                    song={song}
                    contentContianerClassName={contentContianerClassName}
                    songNameClassName={songNameClassName}
                    songArtistClassName={songArtistClassName}
                    hasBtn={hasBtn}
                    onItemClick={handleSongItemClick(song, index)}
                    onBtnClick={onBtnClick}
                  />
                );
              })
            ) : (
              <>
                <div className={styles.skeleton}>
                  <LoadingSkeleton isSchedule />
                  <LoadingSkeleton isSchedule />
                  <LoadingSkeleton isSchedule />
                  <LoadingSkeleton isSchedule />
                  <LoadingSkeleton isSchedule />
                </div>
              </>
            )}
          </div>
        )}
      </div>
      {isActive && (
        <div onClick={handlebackDropClick} className={styles.backDrop} />
      )}
    </>
  );
};

SearchBar.propTypes = {
  containerClassName: propTypes.string,
  songsListContainerClassName: propTypes.string,
  songNameClassName: propTypes.string,
  songArtistClassName: propTypes.string,
  hasBtn: propTypes.bool,
  contentContianerClassName: propTypes.string,
  onBtnClick: propTypes.func,
  inputRef: propTypes.any,
  alreadyAddedSongIds: propTypes.array,
};

SearchBar.defaultProps = {
  containerClassName: "",
  songsListContainerClassName: "",
  songNameClassName: "",
  alreadyAddedSongIds: [],
  songArtistClassName: "",
  hasBtn: false,
  contentContianerClassName: "",
  onBtnClick: noop,
  inputRef: null,
};

export default SearchBar;
