import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import cx from "classnames";
import propTypes from "prop-types";

import Button, { BUTTON_TYPES } from "../button/Button";
import MusicPlayer from "../musicPlayer";
import SearchBar from "../searchBar/SearchBar";

import SideDrawer from "../sideDrawer/SideDrawer";

import { ICONS } from "../../constants/icons.constants";
import songsStore from "../../zustand/songs.store";
import { fetchSongs } from "../../actions/songs.actions";
import musicPlayerStore from "../../zustand/musicPlayer.store";

import styles from "./appSkeleton.module.scss";
import { getFormattedSongs } from "../../zustand/general.helper";
import playlistStore from "../../zustand/playlist.store";
import { fetchCustomerData } from "../../actions/user.actions";
import { getIngoreSongIds } from "../../helpers/music.helpers";
import ignoredSongsStore from "../../zustand/ignoreSong.store";
import userStore from "../../zustand/user.store";
import { showToaster } from "../../helpers/toaster.helpers";

const AppSkeleton = (props) => {
  const { children } = props;

  const { setIsLoading, setSongs } = songsStore((state) => ({
    setIsLoading: state.setIsLoading,
    setSongs: state.setSongs,
  }));
  const { setSongs: setMusicPlayerSongs, setPlayList } = musicPlayerStore(
    (state) => ({
      setSongs: state.setSongs,
      setPlayList: state.setPlayList,
    })
  );
  const { setIsLoading: setIsPlaylistFetching, setPlaylists } = playlistStore(
    (state) => ({
      setIsLoading: state.setIsLoading,
      setPlaylists: state.setPlaylists,
    })
  );
  const { setSongs: setIgnoredSongs } = ignoredSongsStore((state) => ({
    setSongs: state.setSongs,
  }));
  const { user, userOffline } = userStore((state) => ({
    user: state.user,
    userOffline: state.userOffline,
  }));

  console.log("AppSkeleton");

  // version/update check
  const [isChecking, setIsChecking] = useState(true);
  const [updateAvailable, setUpdateAvailable] = useState(false);

  useEffect(() => {
    const handleUpdate = () => {
      setUpdateAvailable(true);
    };

    window.addEventListener("swUpdateAvailable", handleUpdate);

    // Initial update check
    setTimeout(() => {
      setIsChecking(false);
    }, 1000);

    return () => window.removeEventListener("swUpdateAvailable", handleUpdate);
  }, []);

  //* Automatic update on update available
  const applyUpdate = () => window.location.reload()

  useEffect(() => {
    if (updateAvailable) {
      applyUpdate();
    }
  }, [updateAvailable]);

  const handleRefresh = async () => {
    if (userOffline) {
      return showToaster(
        "error",
        "You are offline, please check your internet connection"
      );
    }
    setIsLoading(true);
    setIsPlaylistFetching(true);
    const [songsResponse, playlistsResponse] = await Promise.allSettled([
      fetchSongs(),
      fetchCustomerData(),
    ]);
    const { value: songsData } = songsResponse;
    const { value: playlistsData } = playlistsResponse;
    const { playlists } = playlistsData || {};
    const ingoreSongIds = getIngoreSongIds(playlists);
    setIgnoredSongs(ingoreSongIds);
    setSongs(getFormattedSongs(songsData));
    setPlaylists(playlists);
    const songs = getFormattedSongs(playlists?.[0]?.songs || []);
    setMusicPlayerSongs(songs);
    setPlayList(playlists?.[0]);
    setIsLoading(false);
    setIsPlaylistFetching(false);
  };

  const currentRoute = useLocation();
  const { pathname } = currentRoute;
  const { royaltyOnly } = user || {};

  return (
    <>
      <div
        className={cx(styles.container, {
          [styles.homepage]: currentRoute.pathname === "/",
        })}
      >
        <div className={styles.bodyContainer}>
          <SideDrawer />
          <div className={styles.rightContainer}>
            {(royaltyOnly || pathname === "/") && (
              <div
                className={styles.searchBar}
                style={{
                  justifyContent: !royaltyOnly && pathname === "/" ? "end" : "",
                }}
              >
                {royaltyOnly && <SearchBar />}
                {pathname === "/" && (
                  <Button
                    type={BUTTON_TYPES.TERTIARY}
                    onClick={handleRefresh}
                    className={styles.btn}
                    hasIcon
                    iconType={ICONS.REFRESH}
                  >
                    Refresh
                  </Button>
                )}
              </div>
            )}

            <div
              style={{
                paddingTop: royaltyOnly || pathname === "/" ? 0 : "5rem",
              }}
              className={styles.childrenContainer}
            >
              {children}
            </div>
          </div>
        </div>
        <div className={styles.footerContainer}>
          <MusicPlayer />
        </div>
      </div>
      {/* {updateAvailable && (
        <div className={styles.UpdateContainer}>
          <div className={styles.updateContent}>
            <h3>New update available</h3>
            <Button onClick={applyUpdate} type={BUTTON_TYPES.PRIMARY}>
              Update
            </Button>
          </div>
        </div>
      )} */}
    </>
  );
};

AppSkeleton.propTypes = {
  children: propTypes.element,
};

AppSkeleton.defaultProps = {
  children: <></>,
};

export default AppSkeleton;
