import { get, isEmpty } from "lodash";
import axios from "../axios";

import { TOKENS } from "../constants/general.constants";
import { userService } from "../services/user.service";

export const doLogin = async (username, password) => {
  try {
    const response = await userService.loginUser({ username, password });

    const status = get(response, "data.status", 2);

    const accessToken = get(response, "data.tokens.accessToken", "");
    const refreshToken = get(response, "data.tokens.refreshToken", "");

    if (status === 2) return false;
    axios.defaults.headers["authorization"] = accessToken;
    localStorage.setItem(TOKENS.ACCESS_TOKEN, accessToken);
    localStorage.setItem(TOKENS.REFRESH_TOKEN, refreshToken);

    return true;
  } catch (error) {
    return false;
  }
};

export const fetchCustomerData = async () => {
  try {
    const customerDataResponse = await userService.fetchCustomerData();
    const { data } = customerDataResponse || {};

    const { playlists, customer, status } = data || {};

    return {
      customer,
      playlists,
      status,
    };
  } catch (error) {
    return {};
  }
};

export const doTokenRefresh = async () => {
  try {
    const { data } = await userService.getNewAccessToken({
      refreshToken: localStorage.getItem(TOKENS.REFRESH_TOKEN),
    });
    const newToken = data?.token || "";

    if (isEmpty(newToken)) return {};

    localStorage.setItem(TOKENS.ACCESS_TOKEN, newToken);
    axios.defaults.headers["authorization"] = newToken;

    return true;
  } catch (error) {
    return false;
  }
};
