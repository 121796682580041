import * as React from "react";
const CheckIcon = (props) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 3C7.49 3 3 7.49 3 13C3 18.51 7.49 23 13 23C18.51 23 23 18.51 23 13C23 7.49 18.51 3 13 3ZM17.78 10.7L12.11 16.37C11.97 16.51 11.78 16.59 11.58 16.59C11.38 16.59 11.19 16.51 11.05 16.37L8.22 13.54C7.93 13.25 7.93 12.77 8.22 12.48C8.51 12.19 8.99 12.19 9.28 12.48L11.58 14.78L16.72 9.64C17.01 9.35 17.49 9.35 17.78 9.64C18.07 9.93 18.07 10.4 17.78 10.7Z"
      fill="#02BC7D"
    />
  </svg>
);
export default CheckIcon;
