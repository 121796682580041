import { isEmpty, map } from "lodash";
import React, { useState } from "react";
import _map from "lodash/map";

import {
  DAYS,
  HOURS,
  MINS,
  TIME_TYPE,
} from "../../pages/schedule/scheduleEditPage/scheduleEditPage.constants";
import Dropdown from "../dropdown";
import PlaylistSong from "../playlistSong/PlaylistSong";
import Button from "../button/Button";

import { setSchedule } from "../../actions/playlist.actions";
import { getFormattedTime } from "./helpers";
import { showToaster } from "../../helpers/toaster.helpers";

import styles from "./scheduleToPlaySongPopUp.module.scss";
import playlistStore from "../../zustand/playlist.store";
import userStore from "../../zustand/user.store";

const ScheduleToPlaySongPopUp = ({ playlistId, setIsVisible, from }) => {
  const [values, setValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const arr = DAYS;

  const { updatePlaylist, playlists } = playlistStore((state) => ({
    updatePlaylist: state.updatePlaylist,
    playlists: state.playlists,
  }));

  const { userOffline } = userStore((state) => ({
    userOffline: state.userOffline,
  }));

  const handleSetPlayingHour = (id) => (e) => {
    setValues({
      ...values,
      selectedPlayingHours: {
        ...values.selectedPlayingHours,
        [id]: e.value,
      },
    });
  };

  const setSelectedPlaylist = (id) => {
    if (!isEmpty(values)) {
      if (values.selectedPlaylists[id]) {
        const selectedPlaylists = { ...values.selectedPlaylists };
        delete selectedPlaylists[id];
        setValues({
          selectedPlaylists: { ...selectedPlaylists },
        });
        return;
      }
    }

    setValues({
      selectedPlaylists: { ...values.selectedPlaylists, [id]: id },
    });
  };

  const handleSave = async () => {
    if (userOffline) {
      return showToaster(
        "error",
        "You are offline, please check your internet connection"
      );
    }
    const days = map(values.selectedPlaylists, (day, index) => {
      return day;
    });
    if (isEmpty(days)) {
      showToaster("error", "Please select at least one day");
      return;
    }

    const selectedTime = getFormattedTime(values.selectedPlayingHours);
    const isScheduleAlreadyAdded = map(playlists, (i) => {
      if (i.schedule) {
        return i.schedule.find(
          (i) =>
            i.time === selectedTime && i.days.some((day) => days.includes(day))
        );
      }
      return false;
    }).some((i) => i);

    if (isScheduleAlreadyAdded) {
      showToaster("error", "Schedule already added");
      return;
    }
    const existingSchedules =
      playlists.find((i) => i.id === playlistId).schedule || [];

    const schedule = [
      ...existingSchedules,
      {
        is_shuffled: false,
        time: getFormattedTime(values.selectedPlayingHours),
        days,
      },
    ];

    setIsLoading(true);
    await setSchedule(playlistId, { schedule });
    setIsLoading(false);
    setIsVisible(false);
    updatePlaylist({ schedule }, playlistId);
  };

  const onBackgroundClick = (e) => {
    if (e) e.stopPropagation();
    setIsVisible(false);
  };

  return (
    <>
      {from === "schedule" && (
        <div className={styles.backdrop} onClick={onBackgroundClick} />
      )}
      <div
        className={`${styles.modal} ${
          from === "schedule" ? styles.centerModal : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className={styles.dropdownList}>
          {_map(arr, (ele, index) => {
            return (
              <PlaylistSong
                songName={ele.label}
                dropdownView
                dropdownDaysView
                id={ele.value}
                setSelectedPlaylist={setSelectedPlaylist}
                dropDown={styles.weekDays}
                dropdownContainer={styles.container}
              />
            );
          })}
        </div>
        <div className={styles.dropDown}>
          <Dropdown
            defaultValue={HOURS[0]}
            arr={HOURS}
            setValue={handleSetPlayingHour("hours")}
            suffix=""
          />
          <Dropdown
            defaultValue={MINS[0]}
            arr={MINS}
            setValue={handleSetPlayingHour("mins")}
            suffix=""
          />
          <Dropdown
            defaultValue={TIME_TYPE[0]}
            arr={TIME_TYPE}
            setValue={handleSetPlayingHour("type")}
          />
        </div>
        <div className={styles.btnWrapper}>
          <Button
            onClick={handleSave}
            isLoading={isLoading}
            className={styles.button}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default ScheduleToPlaySongPopUp;
