import * as React from 'react';

const Download = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={25}
    height={24}
    viewBox='0 0 25 24'
    fill='none'
    {...props}>
    <g clipPath='url(#clip0_4296_5719)'>
      <path
        d='M12.4996 11.4195L15.2353 14.4753H13.6366H13.1366V14.9753V21.0998H11.8567V14.9727V14.4727H11.3567H9.7642L12.4996 11.4195ZM9.13885 14.4727C9.13917 14.4727 9.13949 14.4727 9.1398 14.4727L9.13885 14.4727Z'
        fill='#D1D1D1'
        stroke='#D1D1D1'
      />
      <path
        d='M20.5196 8.1875C19.2929 4.8051 16.167 2.3999 12.5054 2.3999C8.84375 2.3999 5.71786 4.8023 4.49107 8.1847C2.19554 8.8147 0.5 11.0015 0.5 13.5999C0.5 16.6939 2.89732 19.1999 5.85446 19.1999H6.92857C7.04643 19.1999 7.14286 19.0991 7.14286 18.9759V17.2959C7.14286 17.1727 7.04643 17.0719 6.92857 17.0719H5.85446C4.95179 17.0719 4.10268 16.6967 3.47054 16.0163C2.84107 15.3387 2.50625 14.4259 2.53571 13.4795C2.55982 12.7403 2.80089 12.0459 3.2375 11.4607C3.68482 10.8643 4.31161 10.4303 5.00804 10.2371L6.02321 9.9599L6.39554 8.9351C6.62589 8.2967 6.94732 7.7003 7.35179 7.1599C7.75108 6.62428 8.22407 6.15344 8.75536 5.7627C9.85625 4.9535 11.1527 4.5251 12.5054 4.5251C13.858 4.5251 15.1545 4.9535 16.2554 5.7627C16.7884 6.1547 17.2598 6.6251 17.6589 7.1599C18.0634 7.7003 18.3848 8.2995 18.6152 8.9351L18.9848 9.9571L19.9973 10.2371C21.4491 10.6459 22.4643 12.0263 22.4643 13.5999C22.4643 14.5267 22.1187 15.4003 21.492 16.0555C21.1846 16.3787 20.8189 16.6349 20.4162 16.8094C20.0134 16.9839 19.5815 17.0731 19.1455 17.0719H18.0714C17.9536 17.0719 17.8571 17.1727 17.8571 17.2959V18.9759C17.8571 19.0991 17.9536 19.1999 18.0714 19.1999H19.1455C22.1027 19.1999 24.5 16.6939 24.5 13.5999C24.5 11.0043 22.8098 8.8203 20.5196 8.1875Z'
        fill='#D1D1D1'
      />
    </g>
    <defs>
      <clipPath id='clip0_4296_5719'>
        <rect
          width={24}
          height={24}
          fill='white'
          transform='translate(0.5)'
        />
      </clipPath>
    </defs>
  </svg>
);
export default Download;
