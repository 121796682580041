import {
  filter,
  findIndex,
  isEmpty,
  map,
  size,
} from "lodash";
import { useEffect, useState, useRef, useCallback, useLayoutEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import propTypes from "prop-types";

import Button from "../../components/button/Button";
import Icons from "../../components/icons";
import PlaylistSong from "../../components/playlistSong/PlaylistSong";
import PlayListSearchSection from "./SearchSection";

import { ICONS } from "../../constants/icons.constants";
import { ROUTES } from "../../constants/routes.constants";
import { getSongs } from "./editPlayListPage.helpers";
import playlistStore from "../../zustand/playlist.store";
import { showToaster } from "../../helpers/toaster.helpers";
import { getPlaylistUpdatePayload } from "../../helpers/playlist.helpers";
import {
  addPlaylist,
  updatePlaylist,
} from "../../actions/playlist.actions";
import {
  getRandomColor,
} from "../../helpers/generel.helpers";

import styles from "./editPlaylistPage.module.scss";
import musicPlayerStore from "../../zustand/musicPlayer.store";
import likedSongsStore from "../../zustand/likedSong.store";
import {
  addSongToFavorites,
  removeSongFromFavorites,
} from "../../actions/songs.actions";
import ThumbNail from "../../components/thumbNail/ThumbNail";
import { shuffleArray } from "../../helpers/music.helpers";
import { MEDIA_URL } from "../../axios";
import imageStore from "../../zustand/image.store";
import userStore from "../../zustand/user.store";
import {
  isExistPlaylistInIndexDb,
  storePlaylistInIndexDB,
} from "../../helpers/indexDb.helpers";
import CheckIcon from "../../components/icons/CheckIcon";
import CircularLoader from "../../components/circularProgressLoader/CircularProgressLoader";
import Loader from "../myPlaylistPage/Loader";

const EditPlaylistPage = () => {
  const [isNameEdit, setIsNameEdit] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isViewMode, setIsViewMode] = useState(true);

  const [visibleSongs, setVisibleSongs] = useState(10);

  // const inputFile = useRef(null);
  const isSomethingChanged = useRef(false);

  const navigate = useNavigate();
  const currentPath = useLocation();
  const params = useParams();

  const observer = useRef();

  const lastSongElementRef = useCallback(node => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setVisibleSongs(prevVisibleSongs => prevVisibleSongs + 5); // Load 5 more songs
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  const { userOffline } = userStore((state) => ({
    userOffline: state.userOffline,
  }));

  const {
    playlists,
    addNewPlaylist,
    updatePlaylist: updatePlaylistState,
    addLikedSongToPlaylist,
    removeUnLikedSongFromPlaylist,
  } = playlistStore((state) => ({
    playlists: state.playlists,
    addNewPlaylist: state.addNewPlaylist,
    updatePlaylist: state.updatePlaylist,
    addLikedSongToPlaylist: state.addLikedSongToPlaylist,
    removeUnLikedSongFromPlaylist: state.removeUnLikedSongFromPlaylist,
  }));

  console.log("Playlists level-1", playlists);

  const { setSongs, setPlayList, isShuffle, setBeforeShuffleSongs } =
    musicPlayerStore((state) => ({
      setSongs: state.setSongs,
      setPlayList: state.setPlayList,
      isShuffle: state.isShuffle,
      setBeforeShuffleSongs: state.setBeforeShuffleSongs,
    }));
  const {
    songs: likedSongs,
    addSong: addLikedSong,
    deleteSong: deleteLikedSong,
  } = likedSongsStore((state) => ({
    songs: state.songs,
    addSong: state.addSong,
    deleteSong: state.deleteSong,
  }));
  const { addImage } = imageStore();

  const currentPlayList = getSongs(params, playlists);
  const {
    songs: initialSongs,
    name,
    isNewPlayList,
    image,
    id,
  } = currentPlayList;

  const [isPlaylistFetching] = useState(false);

  const [values, setValues] = useState({
    visibleImage: "", // this we'll show in the UI
    imageForBE: null, // this we'll send to the server
  });

  // 
  useEffect(() => {
    setValues({
      songs: initialSongs,
      name: name,
      id: id,
    });
  }, [params?.id, playlists]);

  useLayoutEffect(() => {
    // Reset visible songs and scroll to top when playlist ID (slug) changes
    setVisibleSongs(10);
    window.scrollTo(0, 0);
  }, [
    params?.id,
  ]);

  //
  // useEffect(() => {
  //   if (!isNewPlayList) {
  //     setValues((prev) => ({
  //       ...prev,
  //       songs: initialSongs,
  //       id: id,
  //     }));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   const handleBeforeUnload = (e) => {
  //     e.preventDefault();
  //     e.returnValue = "";
  //     window.alert("Are you sure you want to leave this page?");
  //   };

  //   const handleNavigate = (e) => {
  //     e.preventDefault();
  //     window.alert("Navigation is disabled on this page.");
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  const handleEditClick = () => {
    if (userOffline)
      return showToaster(
        "error",
        "You are offline, please check your internet connection"
      );
    setIsViewMode(false);
    setIsNameEdit(!isNameEdit);
  };
  const handleLikeClick = (song, favorite) => async (e) => {
    e.stopPropagation();
    if (userOffline)
      return showToaster(
        "error",
        "You are offline, please check your internet connection"
      );
    const { index = "1.0", id } = song;
    let isSuccessful = false;
    const isAlreadyLiked = favorite;

    if (isAlreadyLiked) {
      deleteLikedSong(id);
      removeUnLikedSongFromPlaylist(song);
    } else {
      addLikedSong(id);
      addLikedSongToPlaylist(song);
    }

    if (isAlreadyLiked) {
      isSuccessful = await removeSongFromFavorites({ song_id: id });
    } else {
      isSuccessful = await addSongToFavorites({ song_id: id, index });
    }

    if (!isSuccessful) {
      if (isAlreadyLiked) {
        addLikedSong(id);
      } else {
        deleteLikedSong(id);
      }
      return;
    }
  };

  const handleInputChange = (id) => (e) => {
    isSomethingChanged.current = true;
    setValues({
      ...values,
      [id]: e.target.value,
    });
  };

  const handleBack = () => {
    navigate(ROUTES.MY_PLAYLIST);
  };

  const addSong = (newSong) => {
    isSomethingChanged.current = true;
    setValues({
      ...values,
      songs: [...(values?.songs || []), newSong],
    });
  };

  const removeSong = (id) => {
    const updatedSongs = filter(values?.songs, (song) => song.id !== id);
    setValues({
      ...values,
      songs: updatedSongs,
    });
  };

  const uploadImage = async (id) => {
    if (!isEmpty(values.visibleImage)) {
      const formData = new FormData();
      formData.append("image", values.imageForBE);
      await addImage(id, formData);
    }
  };

  const handleAddNewPlaylist = async () => {
    setIsLoading(true);
    const payload = getPlaylistUpdatePayload(values);
    const playlistId = await addPlaylist(payload);
    await uploadImage(playlistId);
    setIsLoading(false);
    addNewPlaylist({
      name: values.name,
      id: playlistId,
      color: getRandomColor(),
      songs: values.songs,
      owner: true,
      is_favorite: false,
      schedule: [],
      visibelePhoto: values.visibleImage,
    });
    navigate(ROUTES.MY_PLAYLIST);
  };

  const handelEditPlaylist = async () => {
    setIsLoading(true);
    const payload = getPlaylistUpdatePayload(values);
    // console.log(values);
    await updatePlaylist({ ...payload, id: params.id });
    await uploadImage(params.id);
    setIsLoading(false);
    updatePlaylistState(
      { ...values, visibelePhoto: values.visibleImage },
      params.id
    );
    navigate(ROUTES.MY_PLAYLIST);
  };

  const handleEditSuccess = () => {
    if (isEmpty(values.songs)) {
      showToaster("error", "Please add songs to playlist");
      return;
    }
    if (userOffline)
      return showToaster(
        "error",
        "You are offline, please check your internet connection"
      );

    if (currentPath.pathname === ROUTES.NEW_PLAYLIST) {
      handleAddNewPlaylist();
    }
    if (!isNewPlayList) {
      handelEditPlaylist();
    }
  };

  // const handleUploadImg = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       if (reader.readyState === 2) {
  //         setValues((prev) => ({
  //           ...prev,
  //           visibleImage: reader.result.toString(),
  //           imageForBE: e.target.files[0],
  //         }));
  //       }
  //     };
  //     reader.readAsDataURL(e.target.files[0]);
  //   }
  // };

  const handlePlayClick = async (songId) => {
    // const songsAfterIgnore = filter(values.songs, (song) => !song.ignore);
    let songIndex = findIndex(values.songs, (song) => song.id === songId);

    if (songIndex === -1) {
      songIndex = 0;
    }

    const shuffledSongs = isShuffle
      ? shuffleArray([...values.songs], songIndex)
      : values.songs;

    if (isShuffle) {
      setBeforeShuffleSongs([...values.songs]);
    }

    console.log("SOngs", shuffledSongs);
    console.log("Index", songIndex);
    console.log("playList", { ...params, songIndex });

    setSongs(shuffledSongs, songIndex);
    setPlayList({ ...params, songIndex });
    navigate(ROUTES.HOME);
  };

  // useEffect(() => {
  //   if (isEmpty(params.id)) return;
  //   (async () => {
  //     if (userOffline) return;
  //     setIsPlaylistFetching(true);
  //     const playList = await playlistActions.fetchPlaylistById(params.id);

  //     if (isEmpty(playList)) {
  //       setIsPlaylistFetching(false);
  //       return;
  //     }
  //     setIsPlaylistFetching(false);

  //     setValues(playList);
  //   })();
  // }, [userOffline, params.id]);


  // * needs big improvement
  // useEffect(() => {
  //   console.log("Donwload Image useEffect - 2");
  //   donwloadImage();
  //   donwloadPlayListImage();
  // }, [donwloadImage, donwloadPlayListImage]);

  const [downloading, setDownloading] = useState(false);
  const [downloaded, setDownloaded] = useState(false);
  const [downloadCount, setDownloadCount] = useState(0);

  const handleDownloadClick = async () => {
    setDownloading(true);
    setDownloadCount(0)

    const idExist = await isExistPlaylistInIndexDb(values.id);

    if (idExist) {
      showToaster("error", "Playlist already downloaded");
      setDownloading(false);
      return;
    } else {
      try {
        const updateCount = (count) => {
          setDownloadCount(count)
        }

        const downloadedSongsCount = await storePlaylistInIndexDB(values, updateCount);
        if (downloadedSongsCount === values.songs.length) setDownloaded(true);

        setDownloading(false);
      } catch (error) {
        console.log(error, "error in catch");
        setDownloading(false);
      }
    }

    setDownloading(false);
  };

  // * complete
  useEffect(() => {
    if (isEmpty(values.id)) return;

    (async () => {
      const idExist = await isExistPlaylistInIndexDb(values.id);
      if (idExist) setDownloaded(true);
      else setDownloaded(false);
    })();
  }, [values]);

  useEffect(() => {
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) setVisibleSongs(prevVisibleSongs => prevVisibleSongs + 5); // Load 5 more songs
    });
  }, []);

  useEffect(() => {
    if (lastSongElementRef.current) {
      observer.current.observe(lastSongElementRef.current);
    }
    return () => {
      if (lastSongElementRef.current) {
        observer.current.unobserve(lastSongElementRef.current);
      }
    };
  }, [lastSongElementRef.current]);



  return (
    <div className={styles.pageContent}>
      <div className={styles.left}>
        <div className={styles.header}>
          <div>
            <div onClick={handleBack} className={styles.backIcon}>
              <Icons type={ICONS.BACK_ARROW} />
            </div>
            {!isNewPlayList ? <p>{name}</p> : <p>Create Playlist</p>}
          </div>
        </div>

        <div className={styles.head}>
          <div className={styles.head2}>
            <div
              className={styles.headImg}
            // onClick={() => inputFile.current.click()}
            >
              <ThumbNail
                name={values.name}
                imageFileName={image}
                imgSrc={`${MEDIA_URL}/playlist_images/${image}`}
              />
              {/* <input
                // allow only images
                type="file"
                accept="image/*"
                ref={inputFile}
                className={styles.upload}
                onChange={handleUploadImg}
              /> */}
            </div>
            <div className={styles.mainBox}>
              <div className={styles.box1}>
                <div className={styles.box3}>
                  {!isNameEdit ? (
                    <p className={styles.name}>{values.name}</p>
                  ) : (
                    <input
                      className={styles.nameInput}
                      value={values.name}
                      autoFocus={true}
                      onChange={handleInputChange("name")}
                      onBlur={handleEditClick}
                    />
                  )}
                  <div className={styles.backIcon} onClick={handleEditClick}>
                    <Icons type={ICONS.EDIT} />
                  </div>
                </div>
                <div className={styles.editBtn}>
                  {isViewMode ? (
                    <Button
                      disabled={isEmpty(values.songs)}
                      isLoading={isLoading}
                      onClick={handlePlayClick}
                    >
                      Play
                    </Button>
                  ) : (
                    <Button isLoading={isLoading} onClick={handleEditSuccess}>
                      Save
                    </Button>
                  )}
                </div>
              </div>

              <div className={styles.box2}>
                <p>{`${size(values?.songs)} songs`}</p>
                {downloaded ? (
                  <div className={styles.CheckIcon}>
                    <CheckIcon />
                  </div>
                ) : values?.songs?.length > 0 && (
                  downloading ? (
                    <div>
                      <CircularLoader
                        active={true}
                        progress={downloadCount}
                        max={values?.songs?.length}
                      />
                      <div className={styles.customTooltip}>
                        {`${downloadCount} of ${values?.songs?.length} is downloaded`}
                      </div>
                    </div>
                  ) : (
                    <div onClick={handleDownloadClick}>
                      <Icons type={ICONS.DOWNLOAD} />
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>

        {isPlaylistFetching ? (
          <Loader />
        ) : (
          <div className={styles.songsBox}
          >
            {map(values?.songs?.slice(0, visibleSongs), (item, i) => {
              const { title, artist, id, color, ignore, index, imageFileName } = item;
              const isLastElement = i === visibleSongs - 1;
              return (
                <div
                  key={id + "playlist" + i}
                  onClick={() => handlePlayClick(id)}
                  ref={isLastElement ? lastSongElementRef : null}
                >
                  <PlaylistSong
                    i={i}
                    songName={title}
                    artistName={artist}
                    id={id}
                    color={color}
                    removeSong={removeSong}
                    removeView={!isViewMode}
                    playListView={isViewMode}
                    index={index}
                    isViewMode={isViewMode}
                    liked={likedSongs?.[id]}
                    onLikeClick={handleLikeClick(item, likedSongs?.[id])}
                    imageFileName={imageFileName}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
      {currentPath.pathname === ROUTES.NEW_PLAYLIST ? (
        <PlayListSearchSection
          addSong={addSong}
          alreadyAddedSongIds={map(values?.songs, "id")}
          isViewMode={isViewMode}
          setIsViewMode={setIsViewMode}
        />
      ) : null}
    </div>
  );
};

EditPlaylistPage.propTypes = {
  isEdit: propTypes.bool,
};

EditPlaylistPage.defaultProps = {
  isEdit: false,
};

export default EditPlaylistPage;
