import React, { memo, useState, useRef } from "react";
import propTypes from "prop-types";
import cx from "classnames";
import { get, map, noop, sortBy } from "lodash";
import { useParams } from "react-router-dom";

import ScheduleListPopUp from "../scheduleListPopUp/ScheduleListPopUp";
import Icons from "../icons";
import Button from "../button";
import ThumbNail from "../thumbNail/ThumbNail";
import Checkbox from "../checkbox/Checkbox";

import { BUTTON_TYPES } from "../button/Button";
import { ICONS } from "../../constants/icons.constants";
import { ignoreSong } from "../../actions/playlist.actions";

import styles from "./playlistSong.module.scss";
import { MEDIA_URL } from "../../axios";
import ignoredSongsStore from "../../zustand/ignoreSong.store";
import userStore from "../../zustand/user.store";
import { showToaster } from "../../helpers/toaster.helpers";

const MAP_DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const PlaylistSong = ({
  normalView,
  removeView,
  dropdownView,
  scheduleListView,
  id,
  songName,
  artistName,
  scheduleName,
  totalHours,
  days,
  removeSong,
  playListView,
  setSelectedPlaylist,
  dropdownDaysView,
  index,
  liked,
  dropDown,
  dropdownContainer,
  onLikeClick,
  imageFileName,
}) => {
  const [visible, setVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const currentPlaylistId = useParams().id;
  // const { setIgnoreSong } = playlistStore((state) => state);
  const {
    songs: ignoredSongs,
    addSong: addIgnoreSong,
    deleteSong: removeIgnoreSong,
  } = ignoredSongsStore((state) => ({
    songs: state.songs,
    addSong: state.addSong,
    deleteSong: state.deleteSong,
  }));

  const { userOffline } = userStore((state) => ({
    userOffline: state.userOffline,
  }));

  // const navigate = useNavigate();

  const timeOutRef = useRef(null);

  const handleRemove = () => {
    removeSong(id);
  };

  const handleScheduleInfo = (e) => {
    e.stopPropagation();
    setVisible((prevState) => !prevState);
  };

  const handleIgnore = async (e) => {
    e.stopPropagation();
    if (userOffline) {
      return showToaster(
        "error",
        "You are offline, please check your internet connection"
      );
    }
    if (timeOutRef.current) {
      clearTimeout(timeOutRef.current);
    }

    const idForIngoreSong = id + currentPlaylistId;
    const newIgnore = get(ignoredSongs, idForIngoreSong, false) ? false : true;

    if (newIgnore) {
      addIgnoreSong(idForIngoreSong);
    } else {
      removeIgnoreSong(idForIngoreSong);
    }

    // // setIsIgnore(newIgnore);
    const isSuccess = await ignoreSong(currentPlaylistId, id, {
      ignore: newIgnore,
    });

    if (!isSuccess) {
      if (newIgnore) {
        removeIgnoreSong(idForIngoreSong);
      } else {
        addIgnoreSong(idForIngoreSong);
      }
    }
  };

  const handleItemClick = () => {
    if (dropdownView || dropdownDaysView) {
      setIsChecked(!isChecked);

      setSelectedPlaylist(id);

      return;
    }
    if (scheduleListView) {
      // navigate(`/schedule/${id}/edit`);
      return;
    }
  };

  const sortedDays = sortBy(days);

  return (
    <div
      className={cx(styles.container, dropdownContainer)}
      onClick={handleItemClick}
    >
      {!dropdownDaysView && (
        <div className={styles.img}>
          <ThumbNail
            name={songName || scheduleName}
            className={styles.imgThumb}
            hasImage={imageFileName}
            imgSrc={`${MEDIA_URL}/songs_images/${imageFileName}`}
            imageFileName={imageFileName}
          />
        </div>
      )}
      {(normalView || removeView || playListView) && (
        <div className={styles.extraInfo}>
          <p className={styles.text1}>{songName}</p>
          <p className={styles.text2}>{artistName}</p>
        </div>
      )}
      {scheduleListView && (
        <div className={cx(styles.extraInfo, styles.scheduleText)}>
          <p className={styles.text1}>{scheduleName}</p>
          <div className={styles.textBox}>
            <p className={styles.text2}>{totalHours + " " + "Hr"}</p>
            <p className={styles.text2}>
              {map(sortedDays, (item) => MAP_DAYS[item] + ",  ")}
            </p>
          </div>
        </div>
      )}
      {dropdownView && (
        <div className={cx(dropDown, styles.extraInfo, styles.scheduleText)}>
          <p className={styles.text1}>{songName}</p>
        </div>
      )}
      <div className={styles.box}>
        {normalView && (
          <>
            <div
              className={cx(styles.icon, { [styles.like]: liked })}
              onClick={onLikeClick}
            >
              <Icons type={ICONS.LIKE_SONG_LIST} />
            </div>
            <Icons type={ICONS.MORE_INFO} />
          </>
        )}
        {removeView && (
          <div className={styles.removeBtn}>
            <Button type={BUTTON_TYPES.SECONDARY} onClick={handleRemove}>
              Remove
            </Button>
          </div>
        )}
        {scheduleListView && (
          <div className={styles.scheduleInfo}>
            {/* <div onClick={handleScheduleInfo} className={styles.info}>
              <Icons type={ICONS.MORE_INFO} />
            </div> */}
            <div className={styles.list}>
              <ScheduleListPopUp
                id={id}
                visible={visible}
                setVisible={setVisible}
                btnPlace1="Delete"
              />
            </div>
          </div>
        )}
        {playListView && (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              width: "160px",
              justifyContent: "space-between",
            }}
            className={styles.scheduleInfo}
          >
            <div
              onClick={onLikeClick}
              className={cx({ [styles.likedIcon]: liked })}
            >
              <Icons type={ICONS.LIKE_SONG_LIST} />
            </div>
            <div
              onClick={handleIgnore}
              className={cx({
                [styles.likedIcon]: get(
                  ignoredSongs,
                  id + currentPlaylistId,
                  false
                ),
              })}
            >
              <Icons type={ICONS.IGNORE_SONG} />
            </div>
            <div onClick={handleScheduleInfo} className={styles.info}>
              <Icons type={ICONS.MORE_INFO} />
            </div>

            <div className={styles.list}>
              <ScheduleListPopUp
                id={id}
                visible={visible}
                setVisible={setVisible}
                isPlaylist={!playListView}
                btnPlace1="Remove"
                style={styles.listPopUp}
                onDeleteSuccess={handleRemove}
              />
            </div>
          </div>
        )}
        {dropdownView && (
          <Checkbox isChecked={isChecked} setIsChecked={setIsChecked} />
        )}
      </div>
    </div>
  );
};

PlaylistSong.propTypes = {
  normalView: propTypes.bool,
  removeView: propTypes.bool,
  dropdownView: propTypes.bool,
  scheduleListView: propTypes.bool,
  songName: propTypes.string,
  artistName: propTypes.string,
  scheduleName: propTypes.string,
  totalHours: propTypes.string,
  days: propTypes.array,
  id: propTypes.string,
  removeSong: propTypes.func,
  playListView: propTypes.bool,
  setSelectedPlaylist: propTypes.func,
  dropdownDaysView: propTypes.bool,
  setIsStateChanged: propTypes.func,
};

PlaylistSong.defaultProps = {
  dropdownDaysView: false,
  normalView: false,
  setSelectedPlaylist: noop,
  removeView: false,
  dropdownView: false,
  playListView: false,
  scheduleListView: false,
  songName: "",
  artistName: "",
  scheduleName: "",
  totalHours: "",
  days: [],
  id: "",
  removeSong: noop,
  setIsStateChanged: noop,
};

export default memo(PlaylistSong);
