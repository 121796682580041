import { get } from "lodash";
import { handleError } from "../helpers/generel.helpers";
import { showToaster } from "../helpers/toaster.helpers";

import { playlistServices } from "../services/playlist.service";
import { socketServices } from "../services/socket.service";

export const fetchSongs = async (id, userOffline) => {
  // if (userOffline) return;
  try {
    const res = await playlistServices.fetchSongs(id);
    const songs = get(res, "data.songs", []);
    return songs;
  } catch (error) {
    handleError(error);
    return {};
  }
};

export const ignoreSong = async (playlistId, songId, payload) => {
  try {
    let socketPayload = {
      eventname: "song_ignored",
      data: {
        song_id: songId,
        status: payload.ignore,
        playlist_id: playlistId,
      },
    };
    socketServices.sendIgnoreSongStatus(socketPayload);

    await playlistServices.ignoreSong(playlistId, songId, payload);
    return true;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const setSchedule = async (playlistId, payload) => {
  try {
    await playlistServices.setSchedule(playlistId, payload);
    return true;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const addPlaylist = async (payload) => {
  try {
    const res = await playlistServices.addPlaylist(payload);
    const id = get(res, "data.id", "");
    showToaster("success", "Playlist added successfully");
    const eventPayload = {
      eventname: "refresh_playlist",
      data: {},
    };
    await socketServices.refreshPlaylist(eventPayload);

    return id;
  } catch (error) {
    handleError(error);
    return "";
  }
};

export const updatePlaylist = async (payload) => {
  try {
    await playlistServices.updatePlaylist(payload);
    showToaster("success", "Playlist updated successfully");
    return true;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const addImage = async (playlistId, payload) => {
  try {
    await playlistServices.addImage(playlistId, payload);
    return true;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const updateSchedule = async (playlistId, payload) => {
  try {
    await playlistServices.updateSchedule(playlistId, payload);
    return true;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const deleteSchedule = async (playlistId) => {
  try {
    await playlistServices.deteleSchedule(playlistId);
    return true;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const downloadPlaylist = async (playlistId, userOffline) => {
  // ! PENDING: Implement this function
  if (userOffline) {
    return;
  }
};
